import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() {}

    changeExhibitionItem(event) {
	event.preventDefault()
	$(event.currentTarget).parents(".exhibition-chooser").find(".exhibition-item a.active").removeClass('active')
	$(event.currentTarget).parents(".product-exhibition").find(".exhibition-display img").attr("src", $(event.currentTarget).find("img").attr("src"))
	$(event.currentTarget).addClass("active")
    }
}
