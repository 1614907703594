import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {
	initialize() {

	}
 
	showNotes(event) {
		event.preventDefault()
		$(event.target).hide()
		$(event.target).parent(".notes").find(".textarea").fadeIn()
	}
}