// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

// ---------- Application Specific JS ---------- //

require("trix")
require("@rails/actiontext")

require("jquery")
require('jquery-ui/themes/base/core.css')
require('jquery-ui/themes/base/menu.css')
require('jquery-ui/themes/base/draggable.css')
require('jquery-ui/themes/base/resizable.css')
require('jquery-ui/themes/base/sortable.css')
require('jquery-ui/themes/base/theme.css')
require("jquery-ui/ui/widgets/draggable")
require("jquery-ui/ui/widgets/resizable")
require("jquery-ui/ui/widgets/sortable")

require("local-time").start()
require("daterangepicker")
require("moment")
require("utilities/flip.min")
require("views/application")

// Stylesheets
import '../stylesheets/application'
