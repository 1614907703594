import { ApplicationController } from "../../application_controller"
import StretchyController from "../helpers/stretchy_controller"
import SelectizeController from "../helpers/selectize_controller"
import TrixController from "../helpers/trix_controller"
import DaterangepickerController from "../helpers/daterangepicker_controller"
import Filepicker from "../helpers/filepicker_controller"
import CodeEditor from "../helpers/code_editor_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    initialize() {
	this.setStretchy()
	this.setSelectize()
	this.setTrix()
	this.setDaterangepicker()
	this.setFilepicker()
	this.radioChangeFormRefresh()
	this.setCodeEditor()
    }

    setStretchy() {
	var stretchy_controller = new StretchyController()
	stretchy_controller.create('.section-form.new-form .short-form-fields, .section-form.edit-form .short-form-fields')
    }

    // TODO: Right now .section-form is being passed as an element. This is not write as,
    // everytime the section_form controller is initialized, all the .section-form elements of the page
    // are sent to selectize. We should only be sending the select elements of this controller to selectize.
    setSelectize() {
	var selectize_controller = new SelectizeController()
	selectize_controller.create('.section-form')
    }

    // TODO: Right now .section-form is being passed as an element. This is not write as,
    // everytime the section_form controller is initialized, all the .section-form elements of the page
    // are sent to selectize. We should only be sending the select elements of this controller to selectize.
    destroySelectize() {
	var selectize_controller = new SelectizeController()
	selectize_controller.destroy('.section-form')
    }

    setTrix() {
	let trix_controller = new TrixController()
	let trixEditorElements = this.element.querySelectorAll(".section-form trix-editor")
	trix_controller.addHeadingTags(trixEditorElements)
    }

    setDaterangepicker() {
	var daterangepicker_controller = new DaterangepickerController()
	daterangepicker_controller.create('.section-form')
    }

    radioChangeFormRefresh() {
	$(this.element).find('form input[type="radio"]').each((i, element) => {
	    if ($(element).data('form-refresh')) {
		$(element).change(() => {
		    var form_element = $(element).parents('form')[0]
		    form_element.action = form_element.action + '?form_refresh=true'
		    Rails.fire(form_element, "submit")
		})
	    }
	})
    }

    setFilepicker() {
	var filepicker_controller = new Filepicker()
	filepicker_controller.create('.section-form')
    }

    destroyFilepicker() {
	var filepicker_controller = new Filepicker()
	filepicker_controller.destroy('.section-form')
    }

    setCodeEditor() {
	var codeeditor_controller = new CodeEditor()
	codeeditor_controller.create('.section-form')
    }

    destroyCodeEditor() {
	var codeeditor_controller = new CodeEditor()
	codeeditor_controller.destroy('.section-form')
    }

    inputSwapper(event) {
	let swap1 = $(event.target).parents('form').find('.swap1')
	let swap2 = $(event.target).parents('form').find('.swap2')

	swap1.swapWith(swap2)
    }

    focus(event) {
	event.preventDefault()
	$(event.target).parents('.section-form').addClass("focused")

	// To ensure that all the hidden stretchy fields are applied with stretchy
	this.setStretchy()
    }

    blur(event) {
	event.preventDefault()
	$(event.target).parents('.section-form').removeClass("focused")
    }

    choose(event) {
	if ($(event.currentTarget).parents('.choice-form').hasClass('focused')) {
	    event.preventDefault()
	    $(event.currentTarget).prev("input[type='checkbox']").trigger("click")
	}
    }
}
