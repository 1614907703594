$(document).on('turbolinks:load', function() {
	setTimeout(function() {
		let url = new URL(window.location.href)
		let scrollId = 'reacher-' + url.searchParams.get("reacher")
		let element = document.getElementById(scrollId)
		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "center" })

			$(document).on('mouseup', (event) => {
				let container = $(element)

				if (!container.is(event.target) && container.has(event.target).length === 0) {
					if (container.is(`#${scrollId}`)) {
						container.removeAttr('id')
					}
				}
			})
		}
	}, 500)
})
