import { ApplicationController } from "../application_controller"
import ConfirmationDialogController from "../layouts/helpers/confirmation_dialog_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	confirmDestroy(event) {
		var confirmation_dialog_controller = new ConfirmationDialogController()
		confirmation_dialog_controller.show(event)
	}

	saveAsDraft(event) {
		event.preventDefault()

		$(event.target).siblings('.save-as-draft-field').val("yes")

		var form_element = $(event.target).closest('form')[0]
		Rails.fire(form_element, "submit")
	}

	createNatureOfNext(event) {
		event.preventDefault()

		$(event.target).siblings('.create-nature-of-next-field').val("yes")

		var form_element = $(event.target).closest('form')[0]
		Rails.fire(form_element, "submit")
	}

	formModified(event) {
		$(event.target).parents('.deal-edit-form').addClass('unsaved')
	}

	refreshForm(event) {
		// This timeout is necessary to insure that if a form is submitted just before refresh, it has enough time to set the disabled attribute.
		setTimeout(() => {
			if (!$(event.target).closest('form').find('input[type="submit"]').attr('disabled')) {
				// console.log("refreshing...")
				var form_element = $(event.target).closest('form')[0]
				form_element.action = form_element.action + '?form_refresh=true'
				Rails.fire(form_element, "submit")
			} else {
				// console.log("not refreshing...")
			}
		}, 100)
	}

	quantityFocus(event) {
		$(event.target).parent().attr("data-title", $(event.target).attr("data-title"))
	}

	quantityBlur(event) {
		$(event.target).parent().removeAttr("data-title")
	}

	destroyMerchandise(event) {
		event.preventDefault()
		$(event.target).closest('.single-merchandise').find('.delete-merchandise-field').val("true")
		$(event.target).closest('.single-merchandise').addClass('destroyed')

		var form_element = $(event.target).closest('form')[0]
		Rails.fire(form_element, "input")
	}
}
