import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() {}

    connect() {
	if (this.element.dataset.eventCountdownDatetime && this.element.dataset.rootCountdownElement && this.element.dataset.eventCountdownElement) {
	    var tick = Tick.DOM.create(this.element, {
		credits: false,
		didInit: (tick) => {
		    // DateTime Format: 2023-05-04T00:31:00+05:30
		    var counter = Tick.count.down(this.element.dataset.eventCountdownDatetime)

		    tick.root.classList.add("flip-counter-active")
		    document.querySelector(this.element.dataset.rootCountdownElement).classList.add("flip-counter-active")
		    document.querySelector(this.element.dataset.eventCountdownElement).classList.add("flip-counter-active")

		    counter.onupdate = (value) => {
			tick.value = value;
		    }

		    counter.onended = () => {
			tick.root.classList.remove("flip-counter-active")
			document.querySelector(this.element.dataset.rootCountdownElement).classList.remove("flip-counter-active")
			document.querySelector(this.element.dataset.eventCountdownElement).classList.remove("flip-counter-active")
		    }
		}
	    })
	}	
    }

    destroy() {
	Tick.DOM.destroy(this.element)
    }
}
