import { ApplicationController } from "../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	static targets = [ "closeCartButton", "cartAddressChooser", "cartProducts", "cartGuidance", "cartFooter" ]

	initialize() {}

	updateLayout(event) {
		this.setCartHeaderHeight()
	        if (this.hasCartProductsTarget) {
		    $(this.cartProductsTarget).height(this.availableCartHeight())
	        }
	        if (this.hasCartAddressChooserTarget) {
		    $(this.cartAddressChooserTarget).height(this.availableCartHeight())
	        }
	}

	saveAskInfo(event) {
		event.preventDefault()
		let form_element = $(event.target).parents('form')[0]
		Rails.fire(form_element, "submit")
	}

	increaseQuantity(event) {
		this.changeQuantity(event, "increase")
	}

	decreaseQuantity(event) {
		this.changeQuantity(event, "decrease")
	}

	destroyQuantity(event) {
		var quantity_element = $(event.target).closest('.cart-merchandise').find('.cart-merchandise-quantity-changer input.quantity')[0]
		this.changeQuantity(event, "destroy", quantity_element)
	}

	changeQuantity(event, type, quantity_element = null) {
		if (quantity_element) {
			var quantity = $(quantity_element)
		} else {
			var quantity = $(event.target).closest('.cart-merchandise-quantity-changer').find('input.quantity')
		}


		if (type == "increase") {
		    if ((quantity.attr('data-variation-stock') != 'nil') && ((parseInt(quantity.val()) + 1) > parseInt(quantity.attr('data-variation-stock')))) {
			return
		    }
		    quantity.val(parseInt(quantity.val()) + 1)
		} else if (type == "decrease") {
		    quantity.val(parseInt(quantity.val()) - 1)
		} else if (type == "destroy") {
		    quantity.val(0)
		}

		if (quantity.val() == 0) {
		    $(event.target).closest('.cart-merchandise').animateCss('zoomOut', () => {
			$(event.target).closest('.cart-merchandise').css("opacity", "0")
			$(event.target).closest('.cart-merchandise').slideUp()
		    })
		}

		var variation_mold = $(`.product-${quantity.attr('data-product-id')}[data-controller="marketing--products--variations"] select[name="variation_id"]`).attr("data-mold")
		var params = `variation_mold=${variation_mold}&variation_id=${quantity.attr('data-variation-id')}&variation_quantity=${quantity.val()}`

		var varietySelectedElement = $(`.product-${quantity.attr('data-product-id')}[data-controller="marketing--products--variations"] .product-variations-varieties .product-variations-variety.variation_${quantity.attr('data-variation-id')}_variety_id select[name="variety_id"]`)
		if (varietySelectedElement.length) {
		    let variety_id = varietySelectedElement.val()
		    let variety_data = varietySelectedElement.children("option").filter(":selected").text()
		    params += `&variety_id=${variety_id}&variety_data=${variety_data}`
		}

		$(event.target).attr("data-params", params)
	}

	refresh(event) {
	    this.element.classList.add("loading")
	    Rails.fire(this.cartFooterTarget.querySelector('.cart-refresher a'), 'click')
	}

	open(event) {
		event.preventDefault()

		$(this.element).animateCss('fadeIn')

		$(this.element).addClass('active').find('.cart-container').animateCss('slideInRight', () => {
			this.updateLayout(event)
			$("body").addClass('no-overflow')
		})
	}

	close(event) {
		if (!$(this.element).hasClass('active')) {
			return
		}

		if (this.closeCartButtonTarget.contains(event.target)) {
			event.preventDefault()
		}

		if (event && !this.closeCartButtonTarget.contains(event.target) && this.element.querySelector('.cart-container').contains(event.target)) {
			return;
		}

		$(this.element).animateCss('fadeOut')

		$(this.element).find('.cart-container').animateCss('slideOutRight', () => {
			this.element.classList.remove('active')
			$("body").removeClass('no-overflow')
		})
	}

	toggleCartAddressChooser(event) {
		if ($(this.cartAddressChooserTarget).hasClass('active')) {
			this.closeCartAddressChooser(event)
		} else {
			this.openCartAddressChooser(event)
		}
	}

	openCartAddressChooser(event) {
		event.preventDefault()

		if (this.loggedOut()) { return }

		$(this.cartAddressChooserTarget).addClass('active')
		this.updateLayout(event)
		$(this.cartAddressChooserTarget).animateCss('slideInDown')
	}

	closeCartAddressChooser(event) {
		event.preventDefault()
		$(this.cartAddressChooserTarget).animateCss('slideOutUp', () => {
			$(this.cartAddressChooserTarget).removeClass('active')
		})
	}

	openCheckout(event) {
		event.preventDefault()

		if (this.loggedOut()) { return }

		if (!$(this.cartFooterTarget).hasClass('active')) { return }

		if (!$(this.element).find('.cart-address-container .cart-address-btn').hasClass('chosen')) {
			$(this.element).find('.cart-address-container .cart-address-btn svg, .cart-address-container .cart-address-btn span').animateCss('headShake')

			if (!$(this.cartGuidanceTarget).hasClass('active')) {
				$(this.cartGuidanceTarget).addClass('active').animateCss('slideInUp', () => {
					setTimeout(() => {
						$(this.cartGuidanceTarget).animateCss('slideOutDown', () => {
							$(this.cartGuidanceTarget).removeClass('active')
						})
					}, 2000)
				})
			}
			return
		}

	    var notesOptionsTextareaElement = this.cartFooterTarget.querySelector('.notes-options textarea')
	    if (notesOptionsTextareaElement && !notesOptionsTextareaElement.getAttribute("data-textarea-listeners-added")) {
		this.setTextareaFieldHeight(notesOptionsTextareaElement)
		notesOptionsTextareaElement.addEventListener("input", (event) => {
		    notesOptionsTextareaElement.value = notesOptionsTextareaElement.value.replace(/\r?\n/gi, '')
		    this.cartFooterTarget.querySelector('.pay-options form input[name="cart_note"]').value = notesOptionsTextareaElement.value
		    this.setTextareaFieldHeight(notesOptionsTextareaElement)
		})

		notesOptionsTextareaElement.setAttribute("data-textarea-listeners-added", true)
	    }

		$(this.element).find('.cart-header').addClass('blurred')
		$(this.element).find('.cart-merchandises').addClass('blurred')
		$(this.cartFooterTarget).addClass('open').animateCss('slideInUp')
	}

	closeCheckout(event) {
		if (event && this.cartFooterTarget.contains(event.target)) {
			return;
		}

		if ($(this.cartFooterTarget).hasClass('open')) {
			$(this.cartFooterTarget).animateCss('slideOutDown', () => {
				$(this.cartFooterTarget).removeClass('open').animateCss('slideInUp')
			})
			$(this.element).find('.cart-header').removeClass('blurred')
			$(this.element).find('.cart-merchandises').removeClass('blurred')
		}
	}

    setTextareaFieldHeight(element) {
	element.style.height = "75px"
	element.style.height = element.scrollHeight + 2 + "px"
    }

	availableCartHeight() {
		let availableCartHeight = $(this.element).outerHeight()

		availableCartHeight -= this.cartHeaderHeight()
		availableCartHeight -= this.cartFooterHeight()

		return availableCartHeight
	}

	setCartHeaderHeight() {
		$(this.element).find('.cart-header').css('max-height', this.cartHeaderHeight())
	}

	cartHeaderHeight() {
		let cartHeaderHeight = 0
		cartHeaderHeight += $(this.element).find(".cart-heading").length ? $(this.element).find(".cart-heading").outerHeight() : 0
		cartHeaderHeight += $(this.element).find(".cart-ask-info").length ? $(this.element).find(".cart-ask-info").outerHeight() : 0
		cartHeaderHeight += $(this.element).find(".cart-address-container .cart-address-btn").length ? $(this.element).find(".cart-address-container .cart-address-btn").outerHeight() : 0
		return cartHeaderHeight
	}

	cartFooterHeight() {
		let cartFooterHeight = 0
		cartFooterHeight += $(this.element).find(".cart-footer").length ? $(this.element).find(".cart-footer").outerHeight() : 0
		return cartFooterHeight
	}

	loggedOut() {
		if ($(this.element).hasClass('logged-out')) {
			Rails.fire(this.closeCartButtonTarget, 'click')
			Rails.fire($('.cart-buttons[data-controller="marketing--carts--buttons"] .login-btn')[0], 'click')
			return true
		} else {
			return false
		}
	}

	thankyouPageReload(event) {
		event.preventDefault()

		window.location.reload(true)
	}

	closeMessenger(event) {
	    event.preventDefault()

	    this.element.classList.remove('messengering')
	}
}
