import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {
	initialize() {

	}

	showDueDate(event) {
		event.preventDefault()
		$(event.target).hide()
		$(event.target).parent(".due-date").find(".options").fadeIn()
	}

	chooseDueDate(event) {
		event.preventDefault()
		$(event.target).addClass("active")
		$(event.target).siblings().removeClass("active")

		$(event.target).parents(".due-date").find(".date").hide()

		if ($(event.target).hasClass("no-due-date")) {
			$(event.target).parents(".due-date").find(".date .start-due-date").val("")
			$(event.target).parents(".due-date").find(".date .end-due-date").val("")

		} else if ($(event.target).hasClass("end-date")) {
			$(event.target).parents(".due-date").find(".date").fadeIn()
			$(event.target).parents(".due-date").find(".date .start-due-date").hide()
			$(event.target).parents(".due-date").find(".date span").hide()

			$(event.target).parents(".due-date").find(".date .start-due-date").val("")

		} else if ($(event.target).hasClass("start-end-date")) {
			$(event.target).parents(".due-date").find(".date").fadeIn()
			$(event.target).parents(".due-date").find(".date .start-due-date").show()
			$(event.target).parents(".due-date").find(".date span").show()
		}
	}
 
	showNotes(event) {
		event.preventDefault()
		$(event.target).hide()
		$(event.target).parent(".notes").find(".textarea").fadeIn()
	}

	clickCompletedTasks(event) {
		event.preventDefault()
		$(event.target).closest(".todos").siblings(".bucket-list-info").find("a.bucket-name")[0].click()
	}
}