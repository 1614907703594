import { ApplicationController } from "../../application_controller"
import SelectizePublicController from "../../layouts/helpers/selectize_public_controller"

export default class extends ApplicationController {
    initialize() {}

    connect() {
	var selectize_public_controller = new SelectizePublicController()
	selectize_public_controller.create(this.element)
    }

    disconnect() {
	var selectize_public_controller = new SelectizePublicController()
	selectize_public_controller.destroy(this.element)
    }
}
