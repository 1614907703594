import { ApplicationController } from "../application_controller"
import Trix from 'trix'

export default class extends ApplicationController {
	initialize() {
		this.onTrixChange()
		this.openExternalLinksInNewWindow()
	}

	openExternalLinksInNewWindow() {
		$(this.element).on("click", '.trix-content a', function(event) {
			var el = event.target
			if (el.tagName === "A" && !el.isContentEditable && el.host !== window.location.host) {
				el.setAttribute("target", "_blank")
			}
		})
	}

	onTrixChange() {
		this.element.addEventListener("trix-change", (event) => {
			this.keywordChooser(event)
		})
	}

	keywordChooser(event) {
		let element = event.target

		let [startPosition, endPosition] = element.editor.getSelectedRange()
		let lastWord = element.editor.getDocument().toString().substr(0, startPosition).split(new RegExp('[\n ]', 'g')).splice(-1)[0]
		let keywordSymbol = lastWord.substr(0, 1)

		let keywordHelpers = $(element).siblings('.keyword-helpers')
		let keywordType = keywordSymbol === '@' ? 'mention' : (keywordSymbol === '#' ? 'watchword' : null)

		let cleanSelectize = (selectize) => {
			if (selectize) {
				selectize.clear()
				selectize.off('item_add', itemAddHandler)
				$(element).off("keydown", keyDownHandler)
			}
		}

		// reset all keywordHelpers before proceeding
		keywordHelpers.find(`.helper`).removeClass('active')
		keywordHelpers.find('.helper select').each((i, element) => {
			cleanSelectize(element.selectize)
		})

		// If this condition is not met, then this function need not do anything further.
		if (!(keywordHelpers.length && keywordType && startPosition === endPosition && startPosition > 0)) { return }

		let keywordString = lastWord.substr(1)
		let cursorRect = element.editor.getClientRectAtPosition(startPosition - 1)
		let containerRect = $(element).closest('.field')[0].getBoundingClientRect()
		let selectize = keywordHelpers.find(`.helper.${keywordType}s select`)[0].selectize

		keywordHelpers.css({ left: (cursorRect.left - containerRect.left) + 'px', top: (cursorRect.bottom - containerRect.top) + 'px' })
		keywordHelpers.find(`.${keywordType}s`).addClass('active')

		selectize.setTextboxValue(keywordString)
		selectize.refreshOptions()
		element.editor.setSelectedRange(startPosition)

		let keyDownHandler = (event) => {
			let KEY_DOWN = 40
			let KEY_UP = 38
			let KEY_RETURN = 13
			if (selectize.hasOptions && (event.keyCode === KEY_DOWN || event.keyCode === KEY_UP || event.keyCode === KEY_RETURN)) {
				event.preventDefault()
				var newEvent = $.Event('keydown')
				newEvent.keyCode = event.keyCode
				selectize.$control_input.trigger(newEvent)
			}
		}
		$(element).on("keydown", keyDownHandler)

		let itemAddHandler = function(value, item) {
			cleanSelectize(selectize)

			element.editor.setSelectedRange([endPosition - lastWord.length, endPosition])
			element.editor.deleteInDirection("backward")

			let css_class = this.revertSettings.$children.filter(`option[value='${value}']`).data(`${keywordType}-class`)
			css_class = css_class ? ' class="' + css_class + '"' : ""

			let attachment = new Trix.Attachment({
				sgid: this.revertSettings.$children.filter(`option[value='${value}']`).data(`${keywordType}-sgid`),
				content: `<tb-${keywordType}${css_class}>${item.html()}</tb-${keywordType}>`,
				contentType: `application/vnd.timeboard.${keywordType}`
			})
			element.editor.insertAttachment(attachment)
			element.editor.insertString(" ")

			// Fix extra <br> tag added on pressing enter for contenteditable elements in firefox.
			if (navigator.userAgent.indexOf("Firefox") != -1) {
				element.editor.deleteInDirection("forward")
			}
		}
		selectize.on('item_add', itemAddHandler)
	}
}