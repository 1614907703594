import { ApplicationController } from "../../application_controller"
import ConfirmationDialogController from "../helpers/confirmation_dialog_controller"

export default class extends ApplicationController {
	isLoadingIndex = false
	
	initialize() { }

	connect() { }

	submit(event) {
		event.preventDefault()
		$(event.target).parents('form').find('input[type=submit]').click()
	}

	confirmDestroy(event) {
		var confirmation_dialog_controller = new ConfirmationDialogController()
		confirmation_dialog_controller.show(event)
	}

	activeToggle(event) {
		event.preventDefault()
		
		// Store the currentTarget li
		let currentLi = $(event.currentTarget).parents('li')

		if (currentLi.hasClass('active')) {
			// Inactive the currentTarget item
			currentLi.addClass('hide-overflow').find('.actions').animateCss('slideOutLeft', () => {
				currentLi.removeClass('active').removeClass('hide-overflow')
			})
		} else if (currentLi.parents('ul').find('li.active').length) {
			let activeLi = currentLi.parents('ul').find('li.active')
			activeLi.addClass('hide-overflow').find('.actions').animateCss('slideOutLeft', () => {
				activeLi.removeClass('active').removeClass('hide-overflow')

				// Active the currentTarget item
				currentLi.addClass('active').addClass('hide-overflow').find('.actions').animateCss('slideInLeft', () => {
					currentLi.removeClass('hide-overflow')
				})
			})
		} else {
			// Active the currentTarget item
			currentLi.addClass('active').addClass('hide-overflow').find('.actions').animateCss('slideInLeft', () => {
				currentLi.removeClass('hide-overflow')
			})
		}
	}
}