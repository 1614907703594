import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() { }

    connect() {
	this.builder()
    }

    disconnect() { }

    builder() {
	if (document.documentElement.hasAttribute("data-turbolinks-preview")) {
	    $(this.element).find('[data-target="slide"]').hide()
	    return
	}

	// Set height and width
	$(this.element).css("height", $(window).outerHeight())
	this.lastOuterWidth = $(window).outerWidth()

	// Hide & Reset
	$(this.element).find('[data-target="slide"]').hide()

	// Load and Display
	$(this.element).imagesLoaded({ background: '[data-target="slide"]' }, () => {
	    $(this.element).find('[data-target="slide"]').fadeIn()
	})
    }

    resizer() {
	if (this.lastOuterWidth == $(window).outerWidth()) { return }
	this.lastOuterWidth = $(window).outerWidth()

	if (this.resizeTO) clearTimeout(this.resizeTO)
	this.resizeTO = setTimeout(() => {
	    this.builder()
	}, 500)
    }

    goToSlide(event) {
	let slides = this.element.querySelectorAll('[data-target="slide"]')
	for(const slide of slides) {
	    slide.classList.remove("opened")
	    slide.classList.remove("closed")
	    if(slide == event.currentTarget) {
		slide.classList.add("opened")
	    } else {
		slide.classList.add("transitioning")
		slide.classList.add("closed")
	    }
	}
    }

    goBack(event) {
	event.preventDefault()
	event.stopPropagation()

	let slides = this.element.querySelectorAll('[data-target="slide"]')	
	for(const slide of slides) {
	    slide.classList.remove("opened")
	    slide.classList.remove("closed")
	    slide.classList.remove("transitioning")
	}
    }
}
