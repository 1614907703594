import { ApplicationController } from "../../application_controller"
import SelectizePublicController from "../../layouts/helpers/selectize_public_controller"

export default class extends ApplicationController {
    initialize() {}

    connect() {
	var selectize_public_controller = new SelectizePublicController()
	selectize_public_controller.create(this.element)
    }

    disconnect() {
	var selectize_public_controller = new SelectizePublicController()
	selectize_public_controller.destroy(this.element)
    }

    toggleEditAddressEditor(event) {
	event.preventDefault()
	if (event.target.parentNode.nodeName == "svg" || event.target.nodeName == "svg") {
	    this.toggleAddressEditor(event, "pulse", "pulse")
	}
    }

    toggleNewAddressEditor(event) {
	event.preventDefault()
	this.toggleAddressEditor(event, "slideInDown", "slideOutUp")
    }

    toggleAddressEditor(event, animationOpenType, animationCloseType) {
	if ($(this.element).hasClass('edit-mode')) {
	    this.closeAddressEditor(event, animationCloseType)
	} else {
	    $(this.element).siblings('.profile-address').removeClass('edit-mode')
	    this.openAddressEditor(event, animationOpenType)
	}
    }

    openAddressEditor(event, animationOpenType) {
	$(this.element).addClass("edit-mode").find('form').animateCss(animationOpenType)
    }

    closeAddressEditor(event, animationCloseType) {
	$(this.element).find('form').animateCss(animationCloseType, () => {
	    $(this.element).removeClass("edit-mode")
	})
    }
}
