import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() { }

    connect() { }

    disconnect() { }

    open(event) {
	event.preventDefault()
	this.element.classList.add('navigation-opened')
    }

    close(event) {
	event.preventDefault()
	this.element.classList.remove('navigation-opened')
    }

    scroll(event) {
	this.scrollingEffect(event)
    }

    scrollWithOffset(event) {
	this.scrollingEffect(event, this.element.offsetHeight)
    }

    scrollingEffect(event, navigationOffsetHeight = 0) {
	let elementFound = document.getElementById(event.currentTarget.hash.substr(1))

	if(elementFound) {
	    event.preventDefault()

	    this.element.classList.remove('navigation-opened')

	    $('html, body').animate({
		scrollTop: $(event.currentTarget.hash).offset().top - navigationOffsetHeight
	    }, 600)
	}
    }
}
