import { ApplicationController } from "../../application_controller"
import moment from 'moment'

export default class extends ApplicationController {
	static targets = [ "picker", "clear" ]

	initialize() {
		this.setPeriodPicker()
	}

	work(event) {
		event.preventDefault()
		this.helperController().runFilter(event.target.getAttribute('href'))
	}

	completeFilter(paramValue) {
		$(this.element).find('ul li').removeClass('active')
		$(this.element).find(`ul li a[href="${paramValue}"]`).closest('li').addClass('active')
		if (paramValue) {
			$(this.clearTarget).addClass('active')
		} else {
			$(this.clearTarget).removeClass('active')
		}
	}

	clear(event) {
		event.preventDefault()
		this.helperController().runFilter('')
	}

	setPeriodPicker() {
		let periodPickerSettings = {
			autoUpdateInput: false,
			timePicker: true,
			timePickerSeconds: true,
			locale: {
				cancelLabel: 'Clear',
				format: 'dddd, D MMMM YYYY [at] LT',
				separator: ' to ',
				customRangeLabel: 'Custom / Clear'
			},
			ranges: {
				'Today': [moment().startOf('day'), moment().endOf('hour')],
				'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
				'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
				'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
				'This Month': [moment().startOf('month'), moment().endOf('day')],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			}
		}

		$(this.pickerTarget).daterangepicker(periodPickerSettings)

		$(this.pickerTarget).on('apply.daterangepicker', (ev, picker) => {
			this.helperController().runPeriod(picker.startDate.format(), picker.endDate.format())
		})

		$(this.pickerTarget).on('cancel.daterangepicker', (ev, picker) => {
			this.helperController().runPeriod('', '')
		})

		// Setting the initial period for daterangepicker
		var url = this.helperController().getWorkerUrl()
		var params = url.searchParams
		this.completePeriod(params.get('start_at'), params.get('end_at'))
	}

	completePeriod(startDate, endDate) {
		if (startDate && endDate) {
			this.setPeriod(moment(startDate), moment(endDate))
		} else {
			this.resetPeriod()
		}
	}

	setPeriod(startDate, endDate) {
		this.resetPeriod()

		this.setStartPeriod(startDate)
		this.setEndPeriod(endDate)

		$(this.pickerTarget).addClass('active')

		if (!startDate.isSame(endDate, 'day')) {
			$(this.pickerTarget).find('.end-period').addClass('show')
		}
	}

	resetPeriod() {
		var startDate = moment().startOf('day')
		var endDate = moment().endOf('day')

		this.setStartPeriod(startDate)
		this.setEndPeriod(endDate)

		$(this.pickerTarget).removeClass('active')
		$(this.pickerTarget).find('.end-period').removeClass('show')
	}

	setStartPeriod(startDate) {
		$(this.pickerTarget).data('daterangepicker').setStartDate(startDate)
		$(this.pickerTarget).find('.start-period .month').html(this.getMonth(startDate))
		$(this.pickerTarget).find('.start-period .day').html(this.getDay(startDate))
	}

	setEndPeriod(endDate) {
		$(this.pickerTarget).data('daterangepicker').setEndDate(endDate)
		$(this.pickerTarget).find('.end-period .month').html(this.getMonth(endDate))
		$(this.pickerTarget).find('.end-period .day').html(this.getDay(endDate))
	}

	getDay(date) {
		return date.format('D')
	}

	getMonth(date) {
		return (date.year() == moment().year()) ? date.format('MMM') : date.format('MMM YY')
	}

	/* ***** Helpers ***** */

	helperController() {
		var section_helper = $(this.element).closest(".section-helper")
		return this.application.getControllerForElementAndIdentifier(section_helper[0], 'layouts--section--section-helper')
	}
}