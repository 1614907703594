import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {

	onChangeAccessCheckboxes(event) {
		if ($(event.target).val() == 'all') {
			$(event.target).siblings('input[type="checkbox"]').prop('checked', false)
			$(event.target).prop('checked', true)
		} else {
			$(event.target).siblings('input[type="checkbox"][value="all"]').prop('checked', false)
			if (!$(event.target).siblings('input[type="checkbox"]:checked').length) {
				$(event.target).prop('checked', true)
			}
		}
	}

}