import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    create(container_element) {
	let selectizeOptions = {
	    onChange: function(value) {
		var obj = $(this)[0]
		var select_element = obj.$input["0"]
		if ($(select_element).data('form-refresh')) {
		    var form_element = $(select_element).parents('form')[0]
		    var url = new URL(form_element.action)
		    url.searchParams.set('form_refresh', true)
		    form_element.action = url.href
		    Rails.fire(form_element, "submit")
		}

		if ($(select_element).data('form-fire')) {
		    var form_element = $(select_element).closest('form')[0]
		    Rails.fire(form_element, $(select_element).data('form-fire'))
		}
	    },
	    render: {
		item: function(data, escape) {
		    var self = this
		    var field_label = self.settings.labelField
		    var imageUrl = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('image-url')

		    var item = '<div class="item">'
		    if (imageUrl) { item += `<img src="${imageUrl}">` }
		    item += escape(data[field_label])
		    item += '</div>'

		    return item
		},
		option: function(data, escape) {
		    var self = this
		    var field_label = self.settings.labelField
		    var imageUrl = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('image-url')
		    var subline = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('subline')

		    var option = '<div class="option">'
		    if (imageUrl) { option += `<img src="${imageUrl}">` }
		    option += escape(data[field_label])
		    if (subline) { option += `<span class="subline">${subline}</span>` }
		    option += '</div>'

		    return option
		}
	    }
	}

	$(container_element).find('select').each((i, element) => {
	    if ($(element).data('allow-create')) {
		selectizeOptions["create"] = true
	    }

	    $(element).selectize(selectizeOptions)

	    // Needed for Chrome browsers...Anger...Peace.
	    $(element)[0].selectize.$control_input.attr("autocomplete", "disabled")
	})
    }

    destroy(container_element) {
	$(container_element).find('select').each((i, element) => {
	    if (element.selectize) {
		var value = $(element).val()
		element.selectize.destroy()
		$(element).val(value)
	    }
	})
    }
}
