import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
	show(event, isRemote = true) {
		event.preventDefault()

		// Hide Rails UJS default confirmation dialog
		$(document).on('confirm', '[data-confirm]', (e) => {
			return false
		})

		// Remove confirmation-dialog on clicking outside if it exists
		$(document).on('mouseup', (e) => {
			let container = $("#confirmation-dialog")

			// if the target of the click isn't the container...
			// ... nor a descendant of the container
			if (container.length && !container.is(e.target) && container.has(e.target).length == 0) {
				container.remove()
			}
		})

		// Display Customized confirmation dialog
		let link = $(event.currentTarget)
		let message = link.attr('data-confirm')
		let html = `
			<div id="confirmation-dialog">
				<span class="arrow"></span>
				<p>${message}</p>
				<div class="action-btns">
					<a class="btn btn-primary confirm" href="${$(event.currentTarget).attr('href')}" data-method="delete" data-remote="${isRemote}">YES</a>
					<a class="btn close">NO</a>
				</div>
			</div>
			`
		if ($('#confirmation-dialog').length) { $('#confirmation-dialog').remove() }
		link.after(html)
		let top = link.position().top + link.outerHeight() + 22
		let left = link.position().left + (link.outerWidth() / 2) - $('#confirmation-dialog').outerWidth() + 41
		let extra_left = 0
		if (left < -125) {
			extra_left = Math.abs(left) - 125
			left = -125
			let arrow_left = parseFloat($('#confirmation-dialog .arrow').css('left'))

			$('#confirmation-dialog .arrow').css('left', arrow_left - extra_left)
		}
		$('#confirmation-dialog').css({'top' : top, 'left' : left})
		$('#confirmation-dialog a.btn.close').on('click', (e) => { $('#confirmation-dialog').remove() })
	}
}
