import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {
	static targets = [ "cartButton" ]

	initialize() {}

	refreshVariationQuantityChanger(event) {
		var quantity = $(event.target)
		var quantity_value = parseInt(quantity.val())

		if (quantity_value >= 1) {
			$(event.target).closest('.variation-quantity-changer').addClass('active')
		} else {
			$(event.target).closest('.variation-quantity-changer').removeClass('active')
		}
	}

	increaseVariationQuantity(event) {
		this.changeVariationQuantity(event, "increase")
	}

	decreaseVariationQuantity(event) {
		this.changeVariationQuantity(event, "decrease")
	}

	changeVariationQuantity(event, type) {
		var quantity_element = $(event.target).closest('.variation-quantity-changer').find('input.quantity')[0]
		this.cartsController.changeQuantity(event, type, quantity_element)
	}

	openCart(event) {
		this.cartsController.open(event)
	}

	closeCart(event) {
		if (this.cartButtonTarget.contains(event.target)) { return }

		this.cartsController.close(event)
	}

	get cartsController() {
		return this.application.getControllerForElementAndIdentifier(document.getElementsByClassName("cart")[0], "marketing--carts")
	}
}
