import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() {}

    goToSlide(event) {
	event.preventDefault()
	
	let activeSliderNavIndex = Array.from(this.sliderNavigation.children).indexOf(this.activeSliderNavigationChild)
	let newSliderNavIndex = Array.from(this.sliderNavigation.children).indexOf(event.currentTarget)
	let scrollByLeft = (newSliderNavIndex - activeSliderNavIndex) * this.slideWidth

	this.slider.scrollBy({ left: scrollByLeft, top: 0, behavior: 'smooth' })
    }

    onScroll(event) {
	let newSliderNavIndex = parseInt(event.currentTarget.scrollLeft/this.slideWidth)
	this.activeSliderNavigationChild.classList.remove("active")
	this.sliderNavigation.children.item(newSliderNavIndex).classList.add("active")
    }

    get slider() {
	return this.element.querySelector(".slider")
    }

    get sliderNavigation() {
	return this.element.querySelector(".slider-navigation")
    }

    get activeSliderNavigationChild() {
	return this.sliderNavigation.querySelector("a.active")
    }

    get slideWidth() {
	return this.element.querySelector(".slider .slide").clientWidth
    }
}
