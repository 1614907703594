import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {
    static targets = [ "closeProfileButton" ]

    initialize() {}

    open(event) {
	event.preventDefault()

	$(this.element).animateCss('fadeIn')

	$(this.element).addClass('active').find('.my-profile-container').animateCss('slideInRight', () => {
	    $("body").addClass('no-overflow')
	})
    }

    close(event) {
	if (!$(this.element).hasClass('active')) {
	    return
	}

	if (this.closeProfileButtonTarget.contains(event.target)) {
	    event.preventDefault()
	}

	if (event && !this.closeProfileButtonTarget.contains(event.target) && this.element.querySelector('.my-profile-container').contains(event.target)) {
	    return
	}

	$(this.element).animateCss('fadeOut')

	$(this.element).find('.my-profile-container').animateCss('slideOutRight', () => {
	    this.element.classList.remove('active')
	    $(this.element).find(".single-item-content").removeClass('active')
	    $("body").removeClass('no-overflow')
	})
    }

    openMenuItem(event) {
	event.preventDefault()
	event.currentTarget.nextElementSibling.classList.add("active")
    }

    closeMenuItem(event) {
	event.preventDefault()
	event.currentTarget.closest('.single-item-content').classList.remove("active")
    }
}
