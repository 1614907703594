import { ApplicationController } from "../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    static targets = [ "closeGeolocationButton", "searchBar", "searchInput", "searchButton", "searchContent", "geolocationContent", "geolocationLander" ]

    initialize() {
	if (typeof google !== 'undefined') {
	    this.geolocationContentTarget.classList.add("search-on")
	    this.searchBarTarget.classList.add("search-on")
	} else {
	    this.geolocationContentTarget.classList.remove("search-on")
	    this.searchBarTarget.classList.remove("search-on")
	}
    }

    connect() {
	this.getGeoLocation()
    }

    destroy() {}

    updateLayout(event) {
	let availableGeolocationHeight = this.availableGeolocationHeight()
	$(this.geolocationContentTarget).height(availableGeolocationHeight)
	$(this.searchContentTarget).height(availableGeolocationHeight)
    }

    availableGeolocationHeight() {
	let availableGeolocationHeight = $(this.element).outerHeight()

	availableGeolocationHeight -= this.geolocationHeaderHeight()
	availableGeolocationHeight -= this.geolocationSearchBarHeight()

	return availableGeolocationHeight
    }

    geolocationHeaderHeight() {
	return $(this.element).find(".geolocation-heading").length ? $(this.element).find(".geolocation-heading").outerHeight() : 0
    }

    geolocationSearchBarHeight() {
	return $(this.element).find(".geolocation-search-bar.search-on").length ? $(this.element).find(".geolocation-search-bar.search-on").outerHeight() : 0
    }

    closeNotPossibleBox(event) {
	event.preventDefault()
	this.geolocationContentTarget.classList.remove("show-not-possible")
    }

    showSavedAddresses(event) {
	event.preventDefault()
	this.geolocationLanderTarget.classList.add("show-saved-addresses")
    }

    open(event) {
	event.preventDefault()

	$(this.element).animateCss('fadeIn')

	setTimeout(() => {
	    $(this.element).addClass('active').find('.geolocation-container').animateCss('slideInRight', () => {
		this.updateLayout(event)
		$("body").addClass('no-overflow')
	    })
	}, 0)
    }

    close(event) {
	if (!$(this.element).hasClass('active')) {
	    return
	}

	if (this.closeGeolocationButtonTarget.contains(event.target)) {
	    event.preventDefault()
	}

	if (event && !this.closeGeolocationButtonTarget.contains(event.target) && this.element.querySelector('.geolocation-container').contains(event.target)) {
	    return
	}

	$(this.element).animateCss('fadeOut')

	$(this.element).find('.geolocation-container').animateCss('slideOutRight', () => {
	    this.element.classList.remove('active')
	    $("body").removeClass('no-overflow')
	})
    }

    getGeoLocation() {
	if (this.element.classList.contains('geolocation-completed') || this.element.classList.contains('geolocation-pincoded')) {
	    // Don't find geolocation as it was already set.
	} else {
	    if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(this.showPosition, this.showError, { enableHighAccuracy: true })
	    } else {
		console.log("Geolocation is not supported by this browser.")
	    }
	}
    }

    showPosition(position) {
	if (typeof google !== 'undefined') {
	    var geocoder = new google.maps.Geocoder()
	    geocoder.geocode( { location: { lat: position.coords.latitude, lng: position.coords.longitude } }, (results, status) => {
		if (status == google.maps.GeocoderStatus.OK) {
		    var result = results[0]
		    if (result) {
			var pincode_address_component = result.address_components.find(address_component => address_component.types.includes("postal_code"))
			if (pincode_address_component) {
			    var markerBtnElement = document.querySelector('#geolocation .geolocation-marker a')
			    markerBtnElement.dataset.params = "pincode=" + pincode_address_component.long_name
			    Rails.fire(markerBtnElement, 'click')
			} else {
			    console.log("Pincode was not returned when searching by auto-detected position")
			}
		    } else {
			console.log("Result was not returned when searching by auto-detected position")
		    }
		} else if(status == google.maps.GeocoderStatus.ZERO_RESULTS) {
		    console.log("Zero results were returned when searching for auto-detected position")
		} else {
		    console.log('Geocode was not successful for the following reason for auto-detected position: ' + status)
		}
	    })
	} else { // # TODO: remove google_api_key
	    var markerBtnElement = document.querySelector('#geolocation .geolocation-marker a')
	    markerBtnElement.dataset.params = "latlng=" + position.coords.latitude + "," + position.coords.longitude
	    Rails.fire(markerBtnElement, 'click')
	}
    }

    showError(error) {
	switch(error.code) {
	    case error.PERMISSION_DENIED:
		console.log("User denied the request for Geolocation.")
		break
	    case error.POSITION_UNAVAILABLE:
		console.log("Location information is unavailable.")
		break
	    case error.TIMEOUT:
		console.log("The request to get user location timed out.")
		break
	    case error.UNKNOWN_ERROR:
		console.log("An unknown error occurred.")
		break
	}
    }

    searchSuggester(event) {
	if (typeof google !== 'undefined') {
	    var address = this.searchInputTarget.value
	    if (address != "" && address.length >= 3) {
		this.autocompleteService = new google.maps.places.AutocompleteService()
		this.autocompleteService.getPlacePredictions({ input: address, componentRestrictions: { country: "in" } }, (predictions, status) => {
		    if (status == google.maps.places.PlacesServiceStatus.OK) {
			var ulElement = document.createElement("ul")
			predictions.forEach((prediction) => {
			    var li = document.createElement("li")

			    li.dataset.placeId = prediction.place_id
			    li.dataset.action = "click->marketing--geolocations#searchChooser"

			    var main_text = prediction.structured_formatting.main_text
			    var main_text_highlighted = main_text
			    prediction.structured_formatting.main_text_matched_substrings.forEach((matched_substring) => {
				var substring = main_text.substring(matched_substring.offset, (matched_substring.offset + matched_substring.length))
				main_text_highlighted = main_text_highlighted.replace(substring,`<strong>${substring}</strong>`)
			    })
			    li.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 25 35"><style type="text/css">.sv0{clip-path:url(#SVGID_2_);}</style><defs><rect x="0.3" width="22.4" height="33"/></defs><clipPath><use xlink:href="#SVGID_1_"/></clipPath><path class="sv0" d="M11.5 0C5.3 0 0.3 5 0.3 11.2c0 2.5 1.5 6.2 4.6 11.9 2.1 3.8 4.3 7.1 4.4 7.2l1.5 2.3c0.2 0.2 0.4 0.4 0.7 0.4 0.3 0 0.5-0.1 0.7-0.4l1.5-2.3c0.1-0.1 2.2-3.3 4.4-7.2 3.1-5.6 4.6-9.4 4.6-11.9C22.7 5 17.7 0 11.5 0L11.5 0zM16.6 22.2c-2.1 3.8-4.2 7-4.3 7.1l-0.8 1.3 -0.8-1.3c-0.1-0.1-2.2-3.3-4.3-7.1C3.5 17 2 13.3 2 11.2c0-5.2 4.3-9.5 9.5-9.5 5.2 0 9.5 4.3 9.5 9.5C21 13.3 19.5 17 16.6 22.2L16.6 22.2zM16.6 22.2"/><path class="sv1" d="M11.5 4.9c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2c3.4 0 6.2-2.8 6.2-6.2S14.9 4.9 11.5 4.9L11.5 4.9zM11.5 15.6c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5c2.5 0 4.5 2 4.5 4.5S14 15.6 11.5 15.6L11.5 15.6zM11.5 15.6"/></svg><p>${main_text_highlighted}</p><span>${prediction.structured_formatting.secondary_text}</span>`

			    ulElement.appendChild(li)
			})
			this.searchContentTarget.innerHTML = ulElement.outerHTML
			this.searchContentTarget.classList.add("searching")
			this.geolocationContentTarget.classList.add("searching")
		    } else if(status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
			this.searchContentTarget.innerHTML = ""
			this.searchContentTarget.classList.remove("searching")
			this.geolocationContentTarget.classList.remove("searching")
		    } else {
			this.searchContentTarget.innerHTML = ""
			this.searchContentTarget.classList.remove("searching")
			this.geolocationContentTarget.classList.remove("searching")
			console.log('Places was not successful for the following reason: ' + status)
		    }
		})
	    } else {
		this.searchContentTarget.innerHTML = ""
		this.searchContentTarget.classList.remove("searching")
		this.geolocationContentTarget.classList.remove("searching")
	    }
	}
    }

    searchSubmitter(event) {
	event.preventDefault()
    }

    searchChooser(event) {
	if (typeof google !== 'undefined') {
	    this.geocoder = new google.maps.Geocoder()
	    this.geocoder.geocode( { placeId: event.currentTarget.dataset.placeId }, (results, status) => {
		if (status == google.maps.GeocoderStatus.OK) {
		    var result = results[0]
		    if (result) {
			var pincode_address_component = result.address_components.find(address_component => address_component.types.includes("postal_code"))
			if (pincode_address_component) {
			    var markerBtnElement = document.querySelector('#geolocation .geolocation-marker a')
			    markerBtnElement.dataset.params = "pincode=" + pincode_address_component.long_name
			    Rails.fire(markerBtnElement, 'click')
			} else {
			    this.geocoder.geocode( { location: result.geometry.location }, (results, status) => {
				if (status == google.maps.GeocoderStatus.OK) {
				    var result = results[0]
				    if (result) {
					var pincode_address_component = result.address_components.find(address_component => address_component.types.includes("postal_code"))
					if (pincode_address_component) {
					    var markerBtnElement = document.querySelector('#geolocation .geolocation-marker a')
					    markerBtnElement.dataset.params = "pincode=" + pincode_address_component.long_name
					    Rails.fire(markerBtnElement, 'click')
					} else {
					    this.searchContentTarget.innerHTML = "<p class='pincode-not-found'>Could not find your pincode. Try narrowing your search to a more specific nearby area.</p>"
					}
				    }
				} else {
				    this.searchContentTarget.innerHTML = "<p class='pincode-not-found'>Could not find your pincode. Try narrowing your search to a more specific nearby area.</p>"
				}
			    })
			}
		    } else {
			this.searchContentTarget.innerHTML = ""
			this.searchContentTarget.classList.remove("searching")
			this.geolocationContentTarget.classList.remove("searching")
		    }
		} else if(status == google.maps.GeocoderStatus.ZERO_RESULTS) {
		    this.searchContentTarget.innerHTML = ""
		    this.searchContentTarget.classList.remove("searching")
		    this.geolocationContentTarget.classList.remove("searching")
		} else {
		    console.log('Geocode was not successful for the following reason: ' + status)
		}
	    })
	}
    }
}
