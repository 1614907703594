import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    static targets = [ "geolocationButton" ]

    initialize() {}

    openGeolocation(event) {
	this.geolocationsController.open(event)
    }

    closeGeolocation(event) {
	if (this.geolocationButtonTarget.contains(event.target)) { return }

	this.geolocationsController.close(event)
    }

    get geolocationsController() {
	return this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="marketing--geolocations"]'), "marketing--geolocations")
    }
}
