import { ApplicationController } from "../../application_controller"
import Trix from "trix"

addHeadingAttributes()

export default class extends ApplicationController {
    addHeadingTags(elements) {
	elements.forEach(element => {
	    let toolbarElement = element.toolbarElement
	    let buttonGroupBlockTools = toolbarElement.querySelector("[data-trix-button-group=block-tools]")
	    if(buttonGroupBlockTools) {
		buttonGroupBlockTools.removeChild(toolbarElement.querySelector("[data-trix-attribute=heading1]"))
		buttonGroupBlockTools.insertAdjacentHTML("afterbegin", this.headingButtonTemplate)
		toolbarElement.querySelector("[data-trix-dialogs]").insertAdjacentHTML("beforeend", this.dialogHeadingTemplate)
	    }
	})
    }

    get headingButtonTemplate() {
	return `<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-action="x-heading" title="Heading" tabindex="-1">Heading</button>`
    }

    get dialogHeadingTemplate() {
	return `
          <div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading">
            <div class="trix-dialog__link-fields">
              <input type="text" name="x-heading" class="trix-dialog-hidden__input" data-trix-input>
              <div class="trix-button-group">
                <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading1">H1</button>
                <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading2">H2</button>
                <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading3">H3</button>
                <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading4">H4</button>
                <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading5">H5</button>
                <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading6">H6</button>
              </div>
            </div>
          </div>
	`
    }
}

function addHeadingAttributes() {
    Array.from(["h1", "h2", "h3", "h4", "h5", "h6"]).forEach((tagName, i) => {
	Trix.config.blockAttributes[`heading${(i + 1)}`] = { tagName: tagName, terminal: true, breakOnReturn: true, group: false }
    })
}
