import { ApplicationController } from "../application_controller"
import Rails from '@rails/ujs'
import Filepicker from "../layouts/helpers/filepicker_controller"

export default class extends ApplicationController {
    static targets = [ "closeWorkbenchButton", "workbenchButton", "workbenchDarkenTop", "workbenchIlluminate", "workbenchDarkenBottom", "workbenchHighlight", "workbenchRefreshButton" ]

    initialize() {
	this.setFilepicker()
	this.moveItemBoxElementOnDrag = _.throttle(this.moveItemBoxElementOnDrag, 250)
    }

    openWorkbench(event) {
	this.open(event)
    }

    closeWorkbench(event) {
	if (this.workbenchButtonTarget.contains(event.target)) { return }

	this.close(event)
    }

    open(event) {
	event.preventDefault()

	$(this.element).find(".my-workbench-container-wrapper").animateCss('fadeIn')

	$(this.element).addClass('active').find('.my-workbench-container').animateCss('slideInRight', () => {
	    $("body").addClass('no-overflow')
	})

	// Page Zooming
	var pageElement = document.querySelector("[data-controller='marketing--pages']")
	if (pageElement) {
	    var scaleValue = (window.innerWidth - this.element.querySelector(".my-workbench-container").getBoundingClientRect().width) / window.innerWidth
	    pageElement.style.transform = "scale(" + scaleValue + ")"
	    pageElement.style.maxHeight = pageElement.getBoundingClientRect().height * scaleValue + "px"
	    pageElement.style.transformOrigin = "0 0"
	}

	// Enable Dragging
	if (!this.element.getAttribute("data-dragging-listeners-added")) {
	    var workbenchItemBoxes = this.element.querySelectorAll(".my-workbench-container .scroll-wrapper .workbench-item-box")
	    this.enableDragging(workbenchItemBoxes)
	    this.element.setAttribute("data-dragging-listeners-added", true)
	}
    }

    close(event) {
	if (!$(this.element).hasClass('active')) {
	    return
	}

	if (this.closeWorkbenchButtonTarget.contains(event.target)) {
	    event.preventDefault()
	}

	if (event && !this.closeWorkbenchButtonTarget.contains(event.target) && this.element.querySelector('.my-workbench-container').contains(event.target)) {
	    return
	}

	if (event && document.querySelector('.file-picker-image-cropper')) {
	    return
	}

	$(this.element).find(".my-workbench-container-wrapper").animateCss('fadeOut')

	var pageElement = document.querySelector("[data-controller='marketing--pages']")
	if (pageElement) {
	    pageElement.style.removeProperty("transform")
	}

	$(this.element).find('.my-workbench-container').animateCss('slideOutRight', () => {
	    this.illuminate(null)
	    var activeMenuItem = this.element.querySelector(".single-item-content.active")
	    if (activeMenuItem) {
		activeMenuItem.querySelector(".top-heading a.back-btn").dispatchEvent(new CustomEvent('click'))
	    }
	    this.element.classList.remove('active')
	    $("body").removeClass('no-overflow')

	    if (pageElement) {
		pageElement.style.removeProperty("transform-origin")
		pageElement.style.removeProperty("max-height")
	    }
	})
    }

    openMenuItem(event) {
	event.preventDefault()
	event.currentTarget.nextElementSibling.classList.add("active")
	event.currentTarget.closest(".workbench-item-box").setAttribute("draggable", false)

	var fieldElements = event.currentTarget.nextElementSibling.querySelectorAll('form .single-item-switch input, form .section-content .field input, form .section-content .field textarea')
	fieldElements.forEach((element) => {
	    this.updateElementDataUsingField(element, "openMenuItem")

	    if (!element.getAttribute("data-field-listeners-added")) {
		element.addEventListener("focus", (event) => {
		    this.showCharacterLengthDisplay(element)

		    this.addHighlight(element)
		})

		element.addEventListener("paste", (event) => {
		    this.updateElementDataUsingField(element, "pasteMenuItem", event)
		})

		element.addEventListener("input", (event) => {
		    this.updateElementDataUsingField(element, "inputMenuItem", event)
		    this.showCharacterLengthDisplay(element)
		    element.closest("form").querySelector(".submit-btn").classList.add("active")

		    this.addHighlight(element)

		    if(element.closest(".single-item-switch")) {
	    		var wb_section = element.closest(".workbench-item-box").getAttribute("data-wb-section")
			var sectionToUpdate = document.querySelector('[wb-section="' + wb_section + '"]')
			if (sectionToUpdate) {
			    this.illuminate(null)
			    if(sectionToUpdate.getAttribute("wb-switch") == "on") { this.illuminate(wb_section) }
			}
		    }
		})

		element.addEventListener("blur", (event) => {
		    this.hideCharacterLengthDisplay(element)

		    this.removeHighlight(element)
		})

		element.setAttribute("data-field-listeners-added", true)
	    }
	})

	var textareaElements = event.currentTarget.nextElementSibling.querySelectorAll('form .section-content .field textarea')
	textareaElements.forEach((element) => {
	    if (!element.getAttribute("data-textarea-listeners-added")) {
		this.setTextareaFieldHeight(element)
		element.addEventListener("input", (event) => {
		    element.value = element.value.replace(/\r?\n/gi, '')
		    this.setTextareaFieldHeight(element)
		})

		element.setAttribute("data-textarea-listeners-added", true)
	    }
	})

	var wb_section = event.currentTarget.closest(".workbench-item-box").getAttribute("data-wb-section")
	this.illuminate(wb_section)
    }

    closeMenuItem(event) {
	event.preventDefault()
	event.currentTarget.closest('.single-item-content').classList.remove("active")
	event.currentTarget.closest('.single-item-content').querySelector("form .submit-btn").classList.remove("active")
	var formElement = event.currentTarget.closest('.single-item-content').querySelector('form')
	formElement.style.opacity = 0.2
	formElement.reset()
	formElement.style.removeProperty("opacity")
	var formErrorsElement = event.currentTarget.closest('.single-item-content').querySelector("form .error-messages")
	if (formErrorsElement) { formErrorsElement.remove() }
	var formFieldElements = formElement.querySelectorAll(".single-item-switch input, .field input, .field textarea")
	formFieldElements.forEach((field) => {
	    this.updateElementDataUsingField(field, "closeMenuItem")
	})
	event.currentTarget.closest(".workbench-item-box").setAttribute("draggable", true)

	this.illuminate(null)
    }

    showCharacterLengthDisplay(element) {
	if (element.hasAttribute("maxlength")) {
	    element.closest('fieldset').querySelector('legend span').innerHTML = element.getAttribute("maxlength") - element.value.length + " remaining"
	} else {
	    this.hideCharacterLengthDisplay(element)
	}
    }

    hideCharacterLengthDisplay(element) {
	if (element.closest('fieldset')) {
	    element.closest('fieldset').querySelector('legend span').innerHTML = ""
	}
    }

    addHighlight(element) {
	var wb_section = element.closest(".workbench-item-box").getAttribute("data-wb-section")
	if(element.closest(".field")) {
	    var wb_element = element.closest(".field").getAttribute("data-wb-element")
	    var highlightElement = document.querySelector('[wb-section="' + wb_section + '"] [wb-element="' + wb_element + '"]')
	    if (highlightElement.getAttribute("data-background")) { highlightElement = highlightElement.closest("[data-background-target]") }
	    if (highlightElement) {
		var highlightElementBCR = highlightElement.getBoundingClientRect()
		var highlightElementInView = highlightElementBCR.top >= 0 && highlightElementBCR.left >= 0 && highlightElementBCR.bottom <= window.innerHeight && highlightElementBCR.right <= innerWidth
		if (highlightElementInView) {
		    this.setHighlightDimensions(highlightElement)
		} else {
		    highlightElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
		    setTimeout(() => {
			highlightElementBCR = highlightElement.getBoundingClientRect()
			this.setHighlightDimensions(highlightElement)
		    }, 500)
		}
	    }
	}
    }

    setHighlightDimensions(highlightElement) {
	var highlightElementBCR = highlightElement.getBoundingClientRect()
	this.workbenchHighlightTarget.style.top = highlightElementBCR.top + "px"
	this.workbenchHighlightTarget.style.left = highlightElementBCR.left + "px"
	this.workbenchHighlightTarget.style.height = highlightElementBCR.height + "px"
	this.workbenchHighlightTarget.style.width = highlightElementBCR.width + "px"
    }

    removeHighlight(element) {
	var wb_section = element.closest(".workbench-item-box").getAttribute("data-wb-section")
	if(element.closest(".field")) {
	    var wb_element = element.closest(".field").getAttribute("data-wb-element")
	    var highlightElement = document.querySelector('[wb-section="' + wb_section + '"] [wb-element="' + wb_element + '"]')
	    if (highlightElement) {
		this.clearHighlight()
	    }
	}
    }

    clearHighlight() {
	this.workbenchHighlightTarget.style.removeProperty("height")
	this.workbenchHighlightTarget.style.removeProperty("width")
	this.workbenchHighlightTarget.style.removeProperty("top")
	this.workbenchHighlightTarget.style.removeProperty("left")
    }

    updateElementDataUsingField(element, action, event = null) {
	var wb_section = element.closest(".workbench-item-box").getAttribute("data-wb-section")
	if(element.closest(".field")) {
	    var wb_element = element.closest(".field").getAttribute("data-wb-element")
	    var wb_element_name = element.closest(".field").getAttribute("data-wb-element-name")
	    var wb_element_field_name = element.closest(".field").getAttribute("data-wb-element-field-name")
	    var elementToUpdate = document.querySelector('[wb-section="' + wb_section + '"] [wb-element="' + wb_element + '"]')
	    if (elementToUpdate) {
		if (wb_element_field_name == "src") {
		    elementToUpdate.setAttribute("src", element.value)
		} else if (wb_element_field_name == "alt") {
		    elementToUpdate.setAttribute("alt", element.value)
		} else if (wb_element_field_name == "document-src") {
		    if(element.nextElementSibling && element.nextElementSibling.getAttribute("src")) {
			if (action == "openMenuItem") {
			    element.nextElementSibling.setAttribute("reset-src", element.nextElementSibling.getAttribute("src"))
			    elementToUpdate.setAttribute("reset-src", elementToUpdate.getAttribute("src"))
			} else if (action == "inputMenuItem") {
			    elementToUpdate.setAttribute("src", element.nextElementSibling.getAttribute("src"))
			} else if (action == "closeMenuItem") {
			    elementToUpdate.setAttribute("src", elementToUpdate.getAttribute("reset-src"))
			    elementToUpdate.removeAttribute("reset-src")
			    element.nextElementSibling.setAttribute("src", element.nextElementSibling.getAttribute("reset-src"))
			    element.nextElementSibling.removeAttribute("reset-src")
			}

			this.pagesController.setBackgroundImages()
		    }
		} else if (wb_element_field_name == "href") {
		    elementToUpdate.setAttribute("href", element.value)
		} else if (wb_element_field_name == "document-href") {
		} else if (wb_element_field_name == "youtube-src") {
		    if (action == "pasteMenuItem") {
			var pastedText = (event.clipboardData || window.clipboardData).getData('text')
			if (pastedText.startsWith("https://www.youtube.com/watch?v=")) {
			    element.value = ""
			    element.value = pastedText.split("https://www.youtube.com/watch?v=")[1]
			}
		    }

		    var src = elementToUpdate.getAttribute("src")
		    var srcParams = src.split("?")[1]
		    elementToUpdate.setAttribute("src", "https://www.youtube.com/embed/" + element.value + "?" + srcParams)
		} else if (wb_element_field_name == "placeholder") {
		    elementToUpdate.setAttribute("placeholder", element.value)
		} else if (wb_element_field_name == "value") {
		    elementToUpdate.setAttribute("value", element.value)
		} else if (wb_element_field_name == "content") {
		    elementToUpdate.innerHTML = element.value
		}
	    }
	} else if(element.closest(".single-item-switch")) {
	    var sectionToUpdate = document.querySelector('[wb-section="' + wb_section + '"]')
	    if (sectionToUpdate) {
		sectionToUpdate.setAttribute("wb-switch", element.checked ? "on" : "off")
		element.closest(".workbench-item-box").setAttribute("data-wb-switch", element.checked ? "on" : "off")
	    }
	}
    }

    setTextareaFieldHeight(element) {
	element.style.height = "57px"
	element.style.height = element.scrollHeight + 2 + "px"
    }

    illuminate(wb_section) {
	if(wb_section) {
	    var sectionElement = document.querySelector('[wb-section="' + wb_section + '"]')
	    if(sectionElement) {
		var windowHeight = window.innerHeight
		var sectionElementBCR = sectionElement.getBoundingClientRect()

		var hasScrolled = false
		var scrollTopValue = window.pageYOffset + sectionElementBCR.top
		scrollTopValue -= windowHeight >= sectionElementBCR.height ? ((windowHeight - sectionElementBCR.height) / 2.0) : (100.0 / 2.0)

		window.addEventListener("scroll", (event) => { hasScrolled = true }, { once: true })
		window.addEventListener("scrollend", (event) => {
		    var windowHeight = window.innerHeight
		    var sectionElementBCR = sectionElement.getBoundingClientRect()

		    var darkenTopHeight = sectionElementBCR.top
		    var darkenBottomHeight = windowHeight - darkenTopHeight
		    var sectionSmallerThanWindow = windowHeight >= sectionElementBCR.height
		    var sectionElementHalfHeight = sectionSmallerThanWindow ? sectionElementBCR.height / 2.0 : 0
		    this.workbenchDarkenTopTarget.style.height = darkenTopHeight + sectionElementHalfHeight + "px"
		    this.workbenchDarkenBottomTarget.style.height = darkenBottomHeight - sectionElementHalfHeight + "px"
		    this.workbenchDarkenTopTarget.style.maxHeight = darkenTopHeight + sectionElementHalfHeight + "px"
		    this.workbenchDarkenBottomTarget.style.maxHeight = darkenBottomHeight - sectionElementHalfHeight + "px"

		    setTimeout(() => {
			this.workbenchDarkenTopTarget.style.maxHeight = darkenTopHeight + "px"
			var bottomMaxHeight = sectionSmallerThanWindow ? darkenBottomHeight - (sectionElementHalfHeight * 2.0) : 0
			this.workbenchDarkenBottomTarget.style.maxHeight = bottomMaxHeight + "px"
		    }, 100)
		}, { once: true })
		window.scrollTo({ top: scrollTopValue, behavior: "smooth" })
		setTimeout(() => {
		    if (!hasScrolled) { window.dispatchEvent(new CustomEvent('scrollend')) }
		}, 100)
	    }
	} else {
	    this.workbenchDarkenTopTarget.style.maxHeight = this.workbenchDarkenTopTarget.style.height
	    this.workbenchDarkenBottomTarget.style.maxHeight = this.workbenchDarkenBottomTarget.style.height
	    this.clearHighlight()

	    let hasTransitioned = false
	    this.workbenchDarkenTopTarget.addEventListener("transitionstart", (event) => { hasTransitioned = true }, { once: true })
	    this.workbenchDarkenTopTarget.addEventListener("transitionend", (event) => {
		this.workbenchDarkenTopTarget.style.removeProperty("max-height")
		this.workbenchDarkenBottomTarget.style.removeProperty("max-height")
		this.workbenchDarkenTopTarget.style.removeProperty("height")
		this.workbenchDarkenBottomTarget.style.removeProperty("height")
	    }, { once: true })
	    setTimeout(() => {
		if (!hasTransitioned) { this.workbenchDarkenTopTarget.dispatchEvent(new CustomEvent('transitionend')) }
	    }, 100)
	}
    }

    refresh(event) {
	this.element.querySelector('[data-controller="marketing--workbenches"] .workbench-item-box.loading').classList.remove("loading")

	var hasErrorsElement = this.element.querySelector('.my-workbench-container .workbench-item-box .single-item-content.active .section-content .error-messages')
	var activeWorkbenchElement = this.element.querySelector('.my-workbench-container .workbench-item-box .single-item-content.active')

	if(hasErrorsElement) {
	    this.setFilepicker()
	    this.pagesController.setBackgroundImages()
	} else if(activeWorkbenchElement) {
	    var wb_section = activeWorkbenchElement.closest(".workbench-item-box").getAttribute("data-wb-section")
	    var currentSectionElement = document.querySelector('[data-controller="marketing--pages"] [wb-section="' + wb_section + '"]')
	    var currentWorkbenchItemBoxElement = document.querySelector('[data-controller="marketing--workbenches"] .workbench-item-box[data-wb-section="' + wb_section + '"]')

	    if(currentSectionElement && currentWorkbenchItemBoxElement) {
		currentSectionElement.style.opacity = 0.1
		currentSectionElement.style.display = currentSectionElement.getAttribute("wb-switch") == "on" ? "block" : "none"

		var backButtonElement = activeWorkbenchElement.querySelector('.top-heading .back-btn')
		if(backButtonElement) { backButtonElement.dispatchEvent(new CustomEvent('click')) }

		fetch(this.workbenchRefreshButtonTarget.getAttribute("href"))
		    .then(response => response.text())
		    .then(html => {
			var htmlDoc = new DOMParser().parseFromString(html, "text/html")
			var newSectionElement = htmlDoc.querySelector('[data-controller="marketing--pages"] [wb-section="' + wb_section + '"]')
			var newWorkbenchItemBoxElement = htmlDoc.querySelector('[data-controller="marketing--workbenches"] .workbench-item-box[data-wb-section="' + wb_section + '"]')
			if(newSectionElement && newWorkbenchItemBoxElement) {
			    currentSectionElement.replaceWith(newSectionElement)
			    currentWorkbenchItemBoxElement.replaceWith(newWorkbenchItemBoxElement)
			    this.setFilepicker()
			    this.pagesController.setBackgroundImages()
			    var workbenchItemBoxesChanged = this.element.querySelectorAll('.my-workbench-container .scroll-wrapper .workbench-item-box[data-wb-section="' + wb_section + '"]')
			    this.enableDragging(workbenchItemBoxesChanged)
			}
		    })
	    }
	}
    }

    enableDragging(workbenchItemBoxElements) {
	workbenchItemBoxElements.forEach((workbenchItemBoxElement) => {
	    workbenchItemBoxElement.addEventListener('dragstart', (e) => {
		this.element.setAttribute("data-dragging-wb-section", workbenchItemBoxElement.getAttribute("data-wb-section"))

		setTimeout(() => {
		    workbenchItemBoxElement.classList.add('dragging')
		}, 0)
	    })

	    workbenchItemBoxElement.addEventListener('dragenter', (e) => {
		e.preventDefault()
		this.moveItemBoxElementOnDrag(workbenchItemBoxElement, e)
	    })

	    workbenchItemBoxElement.addEventListener('dragover', (e) => {
		e.preventDefault()
	    })

	    workbenchItemBoxElement.addEventListener('dragleave', (e) => {
	    })

	    workbenchItemBoxElement.addEventListener('drop', (e) => {
	    })

	    workbenchItemBoxElement.addEventListener('dragend', (e) => {
		const draggedWbSection = this.element.getAttribute("data-dragging-wb-section")
		const workbenchItemBoxElements = this.element.querySelectorAll('.my-workbench-container .scroll-wrapper [data-wb-section]')
		const draggedWorkbenchItemBoxElement = Array.from(workbenchItemBoxElements).find(element => element.getAttribute('data-wb-section') == draggedWbSection)

		let arrangementElement = draggedWorkbenchItemBoxElement.querySelector('.section-field[data-wb-section-field-name="arrangement"] input')
		arrangementElement.value = Array.from(workbenchItemBoxElements).map(element => element.getAttribute("data-wb-section")).join(",")
		Rails.fire(draggedWorkbenchItemBoxElement.querySelector("form"), "submit")
		arrangementElement.value = ""
		draggedWorkbenchItemBoxElement.classList.remove('dragging')
	    })
	})
    }

    moveItemBoxElementOnDrag(workbenchItemBoxElement, e) {
    	const wbSection = workbenchItemBoxElement.getAttribute("data-wb-section")
	const sectionElement = document.querySelector('[wb-section="' + wbSection + '"]')

	const draggedWbSection = this.element.getAttribute("data-dragging-wb-section")
	const draggedWorkbenchItemBoxElement = this.element.querySelector('.my-workbench-container .scroll-wrapper [data-wb-section="' + draggedWbSection + '"]')
	const draggedSectionElement = document.querySelector('[wb-section="' + draggedWbSection + '"]')

	if(e.clientY < draggedWorkbenchItemBoxElement.getBoundingClientRect().top) {
	    if(workbenchItemBoxElement.previousElementSibling != draggedWorkbenchItemBoxElement) {
		workbenchItemBoxElement.before(draggedWorkbenchItemBoxElement)
		sectionElement.before(draggedSectionElement)
	    }
	} else {
	    if(workbenchItemBoxElement.nextElementSibling != draggedWorkbenchItemBoxElement) {
		workbenchItemBoxElement.after(draggedWorkbenchItemBoxElement)
		sectionElement.after(draggedSectionElement)
	    }
	}
    }

    setFilepicker() {
	var filepicker_controller = new Filepicker()
	filepicker_controller.create('[data-controller="marketing--workbenches"]')
    }

    destroyFilepicker() {
	var filepicker_controller = new Filepicker()
	filepicker_controller.destroy('[data-controller="marketing--workbenches"]')
    }

    submitter(event) {
	let workbenchItemBoxElement = event.currentTarget.closest(".workbench-item-box")
	workbenchItemBoxElement.classList.add("loading")
    }

    get pagesController() {
	return this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="marketing--pages"]'), "marketing--pages")
    }
}
