import { ApplicationController } from "../../application_controller"
import SelectizePublicController from "../../layouts/helpers/selectize_public_controller"

export default class extends ApplicationController {
    initialize() {}

    connect() {
	var selectize_public_controller = new SelectizePublicController()
	selectize_public_controller.create(this.element)
    }

    disconnect() {
	var selectize_public_controller = new SelectizePublicController()
	selectize_public_controller.destroy(this.element)
    }

    toggleEditPersonEditor(event) {
	event.preventDefault()
	if (event.target.parentNode.nodeName == "svg" || event.target.nodeName == "svg") {
	    this.togglePersonEditor(event, "pulse", "pulse")
	}
    }

    togglePersonEditor(event, animationOpenType, animationCloseType) {
	if ($(this.element).hasClass('edit-mode')) {
	    this.closePersonEditor(event, animationCloseType)
	} else {	    
	    this.openPersonEditor(event, animationOpenType)
	}
    }

    openPersonEditor(event, animationOpenType) {
	$(this.element).addClass("edit-mode").find('form').animateCss(animationOpenType)
    }

    closePersonEditor(event, animationCloseType) {
	$(this.element).find('form')[0].reset()

	$(this.element).find('form').animateCss(animationCloseType, () => {
	    $(this.element).find('form .error-messages').remove()
	    $(this.element).removeClass("edit-mode")
	})
    }
}
