import { ApplicationController } from "../../application_controller"
import Filepicker from "../helpers/filepicker_controller"

export default class extends ApplicationController {
    initialize() {
	this.refreshAddMore()
    }

    refreshField(event) {
	let input_destroy = event.currentTarget.querySelector("input[name*='[_destroy]']")
	if(input_destroy.value == "true") {
	    if (event.currentTarget.classList.contains('using-add-more')) {
		event.currentTarget.remove()
	    } else {
		event.currentTarget.classList.add("removed")
	    }

	    this.refreshAddMore()
	}
    }

    artworkFieldsExpanderToggle(event) {
	if(this.element.classList.contains('artwork-fields-expander-active')) {
	    this.element.classList.remove('artwork-fields-expander-active')
	    Array.from(this.element.querySelectorAll('.artwork-fields .field')).forEach((el) => el.classList.remove('field-expander-active'))
	} else {
	    this.element.classList.add('artwork-fields-expander-active')
	    Array.from(this.element.querySelectorAll('.artwork-fields .field')).forEach((el) => el.classList.add('field-expander-active'))
	}
    }

    destroyAsset(event) {
	event.currentTarget.querySelector('input[type="hidden"]').value = "true"
	event.currentTarget.closest('.asset-chooser').classList.remove('has-asset')
    }

    pickAsset(event) {
	var selectedFile = event.target.files[0]
	if (selectedFile) {
	    let assetChooserElement = event.currentTarget.closest('.asset-chooser')
	    let pElement = assetChooserElement.querySelector('.update-asset p')
	    let spanElement = assetChooserElement.querySelector('.update-asset span')

	    if (selectedFile.type.match(/image.*/)) {
		pElement.innerHTML = "Gif Added"
	    } else if (selectedFile.type.match(/video.*/)) {
		pElement.innerHTML = "Video Added"
	    } else if (selectedFile.type.match(/audio.*/)) {
		pElement.innerHTML = "Audio Added"
	    } else {
		pElement.innerHTML = "Asset Added"
	    }

	    if(selectedFile.name.length > 25) {
		spanElement.innerHTML = selectedFile.name.substring(0, 12) + "..." + selectedFile.name.substring(selectedFile.name.length - 12)
	    } else {
		spanElement.innerHTML = selectedFile.name
	    }

	    assetChooserElement.classList.remove('has-asset')
	    assetChooserElement.querySelector('.destroy-asset input[type="hidden"]').value = "false"
	}
    }

    addMore(event) {
	event.preventDefault()

	// Get the link element
	let link = event.currentTarget
	// Save a unique timestamp to ensure the key of the associated array is unique.
	let time = new Date().getTime()
	// Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
	let linkId = link.dataset.id
	// Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
	let regexp = linkId ? new RegExp(linkId, 'g') : null
	// Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
	let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
	// Add the new markup to the form if there are fields to add.
	newFields ? link.parentNode.insertAdjacentHTML('beforebegin', newFields) : null
	// Defining these fields were added using add more button.
	link.parentNode.previousElementSibling.classList.add('using-add-more')
	// If artwork-fields-expander is currently active then display in expander view.
	if(this.element.classList.contains('artwork-fields-expander-active')) {
	    link.parentNode.previousElementSibling.classList.add('field-expander-active')
	}
	// Filepickerize the new element
	var filepicker_controller = new Filepicker()
	filepicker_controller.create(link.parentNode.previousElementSibling)
	// Show/Hide AddMore
	this.refreshAddMore()
    }

    refreshAddMore() {
	let link = this.element.querySelector('a.add-more')
	if(!link) return

	let count = this.element.querySelectorAll('.artwork-fields .field').length
	let limit = parseInt(link.dataset.limit) + 1 // count is field count including the add-more field, hence the + 1 to the limit

	if(count >= limit) {
	    link.parentNode.classList.add('removed')
	} else {
	    link.parentNode.classList.remove('removed')
	}
    }
}
