import { ApplicationController } from "../../application_controller"
import CookiesController from "../../layouts/helpers/cookies_controller"

export default class extends ApplicationController {
	initialize() {}

	connect() {
		let cookies = new CookiesController()
		if (!cookies.hasCookie("ageDeclared")) {
			$('body').addClass('no-overflow blurred')
			$(this.element).addClass("active")
		}
	}

	ageDeclared(event) {
		event.preventDefault()

		$('body').removeClass('no-overflow blurred')
		$(this.element).remove()

		let cookies = new CookiesController()
		cookies.set("ageDeclared", "true", 365)
	}
}