import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	initialize() { }

	refreshVariationQuantityChanger(event) {
		var quantity = $(event.target)
		var quantity_value = parseInt(quantity.val())

		if (quantity_value >= 1) {
			$(event.target).closest('.variation-quantity-changer').addClass('active')
		} else {
			$(event.target).closest('.variation-quantity-changer').removeClass('active')
		}
	}

	increaseVariationQuantity(event) {
	    if (this.geoLocationCompleted()) {
		this.changeVariationQuantity(event, "increase")
	    }
	}

	decreaseVariationQuantity(event) {
	    if (this.geoLocationCompleted()) {
		this.changeVariationQuantity(event, "decrease")
	    }
	}

	changeVariationQuantity(event, type) {
		var quantity_element = $(event.target).closest('.variation-quantity-changer').find('input.quantity')[0]
		this.cartsController.changeQuantity(event, type, quantity_element)
	}

	get cartsController() {
		return this.application.getControllerForElementAndIdentifier(document.getElementsByClassName("cart")[0], "marketing--carts")
	}

	geoLocationCompleted() {
	    var geolocationElement = document.querySelector("#geolocation")
	    if (geolocationElement) {
		if (geolocationElement.classList.contains('geolocation-completed')) {
		    return true
		} else {
		    Rails.fire($('[data-controller="marketing--geolocations--buttons"] .geolocation-btn')[0], 'click')
		    return false
		}
	    } else {
		return true
	    }
	}
}
