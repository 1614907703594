import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	initialize() {
		this.setSortable()
	}

	setSortable() {
		if (this.data.has("sortable")) {
			let sortable_element = $(this.element).find('> ul.section-list-items')
			let sortable_items_selector = "> li.section-list-item:not(.sortable-disabled)"

			let sortable_options = {
				items: sortable_items_selector,
				axis: "y",
			        placeholder: "ui-state-highlight",
			        forcePlaceholderSize: "true",
				cancel: "input, a:not(.sortable-mover), button, trix-editor"
			}

			sortable_options["update"] = function(event, ui) {
				// To run update only from the final container in case of connected sortable.
				if (this === ui.item.parent()[0]) {
					let sortableMover = ui.item.find('.sortable-mover').first()

					let params = sortableMover.attr('data-params').split('&')
					let positionIndex = params.findIndex((param) => { return param.includes('[position]=') })
					if (positionIndex != -1) {
						let listItems = sortableMover.closest('ul.section-list-items').find(sortable_items_selector)
						let newPosition = listItems.index(ui.item) + 1
						if (sortableMover.attr('data-position-type') == "inverse") {
							newPosition = (listItems.length - newPosition) + 1
						}
						params[positionIndex] = `${params[positionIndex].split('=')[0]}=${newPosition}`
						sortableMover.attr('data-params', params.join("&"))
					}

					setTimeout(() => {
						sortableMover.attr('data-sendrequest', "true")
						Rails.fire(sortableMover[0], "click")
						sortableMover.attr('data-sendrequest', "false")
					}, 0)
			    }
			}

			if (this.data.get("sortable").startsWith('connected')) {
				sortable_element.addClass('sortable-connected')
				sortable_options["connectWith"] = ".sortable-connected"

				sortable_options["over"] = function(event, ui) {
					let sortableDisabledItem = $(this).find('> li.section-list-item.sortable-disabled').first()

					let isOverEmptyList = sortableDisabledItem.is(':nth-child(1)')
					let isOverCurrentListWithOnlyOneItem = ui.helper.is(':nth-child(1)') && sortableDisabledItem.is(':nth-child(2)')

					if (isOverEmptyList || isOverCurrentListWithOnlyOneItem) {
						ui.placeholder.prependTo($(this))
					}
				}

				let containerId = this.data.get("sortable").split('-').slice(-1)[0]
				sortable_options["receive"] = function(event, ui) {
					let sortableMover = ui.item.find('.sortable-mover')

					let params = sortableMover.attr('data-params').split('&')
					let containerIndex = params.findIndex((param) => { return param.includes('[bucket_id]=') })
					if (containerIndex != -1) {
						params[containerIndex] = `${params[containerIndex].split('=')[0]}=${containerId}`
						sortableMover.attr('data-params', params.join("&"))
					}
				}
			}

			setTimeout(() => {
				sortable_element.sortable(sortable_options)

				sortable_element.find(`${sortable_items_selector} .sortable-mover`).on('ajax:beforeSend', function(event) {
					if ($(event.target).attr('data-sendrequest') != "true") { event.preventDefault() }
				})
			}, 0)
		}
	}

	onScroll() {
		if ($(this.element).find('.section-list-load-more').length && ($(window).scrollTop() > ($(document).height() - $(window).height() - 1000))) {
			let loadMoreButton = $(this.element).find('.section-list-load-more a.load-more-btn')
			Rails.fire(loadMoreButton[0], "click")
		}
	}

	// isLoadingIndex = false

	// connect() {
	// 	this.data.get("autoload") == 'true' ? this.index() : ''
	// }

	// index(page_no = '') {
	// 	if (this.isLoadingIndex) { return false }
	// 	if (!this.data.has("url-index")) { return false }
	//
	// 	let url = this.data.get("url-index")
	// 	// Adding page number to the url
	// 	if (page_no != '') { !url.match(/page_no=/g) ? (url += (url.match(/[\?]/g) ? '&' : '?') + `page_no=${page_no}`) : '' }
	// 	// Adding number of per page items to the url
	// 	if (this.data.has("per-page")) { !url.match(/per_page=/g) ? (url += (url.match(/[\?]/g) ? '&' : '?') + `per_page=${this.data.get("per-page")}`) : '' }
	// 	// Adding direction to the url
	// 	if (this.data.has("direction")) { !url.match(/direction=/g) ? (url += (url.match(/[\?]/g) ? '&' : '?') + `direction=${this.data.get("direction")}`) : '' }
	// 	// Adding scope to the url
	// 	if (this.data.has("scope")) { !url.match(/scope=/g) ? (url += (url.match(/[\?]/g) ? '&' : '?') + `scope=${this.data.get("scope")}`) : '' }
	//
	// 	this.isLoadingIndex = true
	// 	this.get(url).then(html => {
	// 		this.isLoadingIndex = false
	// 		let replace = page_no == '' ? true : false
	// 		this.refreshListItems(html, replace)
	// 		this.refreshLoadMoreButton(html)
	// 		if (this.data.get("new") == 'true' && !$(this.element).find('.section-list-items .section-form.new-form').length) { this.new() }
	// 	}).catch(error => {
	// 		this.isLoadingIndex = false
	// 		console.log(error)
	// 	})
	// }

	// show() {
	// 	if (!this.data.has("url-show")) { return false }
	//
	// 	this.get(this.data.get("url-show")).then(html => {
	// 		// Do something with the html
	// 	}).catch(error => {
	// 		console.log(error)
	// 	})
	// }

	// new() {
	// 	if (!this.data.has("url-new")) { return false }
	//
	// 	this.get(this.data.get("url-new")).then(html => {
	// 		if (this.data.get("direction") == 'down') { $(this.element).find('.section-list-items').append(html) }
	// 		else if (this.data.get("direction") == 'up') { $(this.element).find('.section-list-items').prepend(html) }
	// 	}).catch(error => {
	// 		console.log(error)
	// 	})
	// }

	// create(event) {
	// 	event.preventDefault();
	//
	// 	let form = $(event.target).closest('form')
	// 	let formData = new FormData(form[0])
	//
	// 	let url = form.attr('action')
	// 	// Adding scope to the url
	// 	if (this.data.has("scope")) { !url.match(/scope=/g) ? (url += (url.match(/[\?]/g) ? '&' : '?') + `scope=${this.data.get("scope")}`) : '' }
	//
	// 	form.find('.error-messages').fadeOut('fast')
	// 	this.post(url, formData).then(html => {
	// 		if ($(html).find('.section-form').length) {
	// 			var element = $(html)
	// 			element.find('.section-form').addClass('focused')
	// 			form.closest('.section-list-item').replaceWith(element)
	// 		} else if ($(html).hasClass('section-list-item')) {
	// 			if (this.data.get("direction") == 'down') { form.closest('.section-list-item').before($(html).animateCss("zoomIn")) }
	// 			else if (this.data.get("direction") == 'up') { form.closest('.section-list-item').after($(html).animateCss("zoomIn")) }
	// 			form.parents('.section-form').removeClass('focused')
	// 			form[0].reset()
	// 		}
	// 	}).catch(error => {
	// 		console.log(error)
	// 	})
	// }

	// edit(event) {
	// 	event.preventDefault()
	// 	this.get($(event.currentTarget).attr('href')).then(html => {
	// 		var element = $(html)
	// 		if (element.hasClass('section-list-item')) {
	// 			element.find('.section-form').addClass('focused')
	// 			$(event.target).closest('.section-list-item').replaceWith(element.animateCss("fadeIn"))
	// 		}
	// 	}).catch(error => {
	// 		console.log(error)
	// 	})
	//
	// }

	// update(event) {
	// 	event.preventDefault();
	//
	// 	let form = $(event.target).closest('form')
	// 	let formData = new FormData(form[0])
	//
	// 	form.find('.error-messages').fadeOut('fast')
	// 	this.post(form.attr('action'), formData).then(html => {
	// 		if ($(html).find('.section-form').length) {
	// 			form.closest('.section-list-item').replaceWith($(html))
	// 		} else if ($(html).hasClass('section-list-item')) {
	// 			form.closest('.section-list-item').replaceWith($(html).animateCss("zoomIn"))
	// 		}
	// 	}).catch(error => {
	// 		console.log(error)
	// 	})
	// }

	// destroy(event) {
	// 	event.preventDefault()
	// 	this.delete($(event.currentTarget).attr('href')).then(html => {
	// 		if (html == '') {
	// 			$(event.target).closest('.section-list-item').animateCss('bounceOut', function() {
	// 				$(event.target).closest('.section-list-item').remove()
	// 				if ($('#confirmation-dialog').length) { $('#confirmation-dialog').remove() }
	// 			})
	// 		}
	// 	}).catch(error => {
	// 		console.log(error)
	// 	})
	// }

	// loadMore(event) {
	// 	event.preventDefault()
	// 	this.index($(event.target).attr('href'))
	// }

	// showOptions(event) {
	// 	event.preventDefault()
	// 	$(event.target).parents('.section-list-item-options').find('.dropdown').toggle()
	// }

	// refreshListItems(html, replace = true) {
	// 	let old_list_items = $(this.element).find('.section-list-items')
	// 	let new_list_items = $(html).find('.section-list-items')
	// 	if (new_list_items.length) {
	// 		if (old_list_items.length) {
	// 			if (replace) {
	// 				let new_form = old_list_items.find('.section-form.new-form').parents('.section-list-item')
	// 				if (this.data.get("direction") == 'down') { old_list_items.replaceWith(new_list_items.append(new_form).fadeIn()) }
	// 				else if (this.data.get("direction") == 'up') { old_list_items.replaceWith(new_list_items.prepend(new_form).fadeIn()) }
	// 			} else {
	// 				if (this.data.get("direction") == 'down') { old_list_items.prepend($(new_list_items.html()).fadeIn()) }
	// 				else if (this.data.get("direction") == 'up') { old_list_items.append($(new_list_items.html()).fadeIn()) }
	// 			}
	// 		} else {
	// 			$(this.element).prepend(new_list_items.fadeIn())
	// 		}
	// 	}
	// }

	// refreshLoadMoreButton(html) {
	// 	if ($(html).find('.load-more').length) {
	// 		if ($(this.element).find('.load-more').length) {
	// 			$(this.element).find('.load-more').replaceWith($(html).find('.load-more'))
	// 		} else {
	// 			// Note: When direction is down, then load more should prepend and vice-versa
	// 			if (this.data.get("direction") == 'down') { $(this.element).prepend($(html).find('.load-more')) }
	// 			else if (this.data.get("direction") == 'up') { $(this.element).append($(html).find('.load-more')) }
	// 		}
	// 	} else {
	// 		$(this.element).find('.load-more').remove()
	// 	}
	// }
}
