import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    initialize() {}

    openWriteReview(event) {
	event.preventDefault()

	if(document.querySelector('[data-controller="marketing--carts"]').classList.contains('logged-out')) { // User is logged-out
	    Rails.fire(document.querySelector('[data-controller="marketing--carts--buttons"] .login-btn'), 'click')
	} else { // User is logged-in
	    event.currentTarget.closest('.reviews-new').classList.remove('closed')
	}
    }
}
