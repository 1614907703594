import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "closeFlashButton" ]

	initialize() {
		this.cleanExtraOtherLinks()
	}

	connect() {
		this.createUpdateLayoutEvent()
		$(this.element).trigger("updateLayout")
	}

	disconnect() {}

	createUpdateLayoutEvent() {
		$(this.element).on("updateLayout", (event) => {
			$(this.element).height($(window).height())
			$(this.element).find('.center-area').css('paddingTop', ($(window).height() - $(this.element).find('.center-area').height())/2)
		})
	}

	showLoader() {
		$(this.element).find('.center-area').addClass('loading')
	}
	
	needToken(event) {
		event.preventDefault()
		$(this.element).find('#system_person_need_token').attr('disabled', false)
		$(this.element).find('form input[type="submit"]').click()
	}

	closeFlashLogin(event) {
		if (!$(this.element).hasClass('active')) {
			return
		}

		if (this.closeFlashButtonTarget.contains(event.target)) {
			event.preventDefault()
		}

		if (event && !this.closeFlashButtonTarget.contains(event.target) && this.element.querySelector('.system-session-container').contains(event.target)) {
			return;
		}

		$(this.element).animateCss('fadeOut')

		$(this.element).find('.system-session-container').animateCss('slideOutRight', () => {
			this.element.classList.remove('active')
			$("body").removeClass('no-overflow')
		})
	}

	cleanExtraOtherLinks() {
		if ($(this.element).find('.other-links-login-page').css("display") == "none") {
			$(this.element).find('.other-links-login-page').remove()
		}

		if ($(this.element).find('.other-links-start-page').css("display") == "none") {
			$(this.element).find('.other-links-start-page').remove()
		}
	}
}
