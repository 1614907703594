import { Controller } from "stimulus"
const _ = require('lodash')

export class ApplicationController extends Controller {
	initialize() {}

	connect() {}

	disconnect() {}

    get(url) {
        return this.request(this.finalUrl(url), 'GET')
    }

    post(url, body) {
        return this.request(url, 'POST', this.finalBody(body))
    }

    put(url, body) {
        return this.request(url, 'PUT', this.finalBody(body))
    }

    patch(url, body) {
        return this.request(url, 'PATCH', this.finalBody(body))
    }

    delete(url) {
        return this.request(this.finalUrl(url), 'DELETE')
    }
	
	request(url, method, body = "") {
		let requestOptions = {
            method: method,
			credentials: "same-origin",
			headers: {}
        }

		if (body != "") {
			requestOptions.body = body;
		}

		return new Promise((resolve, reject) => {
			fetch(url, requestOptions).then(response => {
				if(response.redirected) {
					fetch(response.url, { credentials: "same-origin" }).then(response => resolve(response))
				} else {
					resolve(response)
				}
			});		
		}).then(response => response.text())
	}

	finalUrl(url) {
		// Adds default layout as false if layout has not been specified
		!url.match(/layout=/g) ? (url += (url.match(/[\?]/g) ? '&' : '?') + 'layout=false') : ''
		return url
	}

	finalBody(body) {
		// Adds default layout as false if layout has not been specified
		!body.has('layout') ? body.set('layout', false) : ''
		return body
	}
}