import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {
	initialize() {

		var window_height = $(window).height() - 53
		$(".all-courses").css("min-height", window_height)

		$(".single-course .course-index ul.chapters li a").click(function(event) {
			event.preventDefault()

			if($(this).parent().hasClass('active')) {
				return
			}

			$(this).parents('ul.chapters').find('li').removeClass('active')
			$(this).parents('ul.chapters').find('li ul.sub-chapters').slideUp()

			$(this).parent().addClass("active")
			$(this).parent().find('ul.sub-chapters').slideDown()
		})

		$(window).scroll(function() {
			if ($(window).scrollTop() > 188) {
				$(".single-course .course .course-content").addClass('fixed')
			} else {
				$(".single-course .course .course-content").removeClass('fixed')
			}

			if ($(window).scrollTop() > 83) {
				$(".all-courses .all-courses-list").addClass('fixed')
			} else {
				$(".all-courses .all-courses-list").removeClass('fixed')
			}
		})

		$(window).scroll(function() {
			if ($(window).scrollTop() > 53) {
				$(".single-course .course-index").addClass('on-scroll')
			} else {
				$(".single-course .course-index").removeClass('on-scroll')
			}

			if ($(window).scrollTop() > 53) {
				$(".all-courses .topics-list").addClass('on-scroll')
			} else {
				$(".all-courses .topics-list").removeClass('on-scroll')
			}
		})
	}
}