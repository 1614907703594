import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() { }

    connect() {
	this.builder()
    }

    disconnect() { }

    builder() {
	if (document.documentElement.hasAttribute("data-turbolinks-preview")) {
	    $(this.element).find(".landing-area-container").hide()
	    return
	}

	// Set zoom scale
	let zoomElement = $(this.element).find(".landing-area-container .slide .laptop-display")
	let windowWidth = $(window).outerWidth()
	let referenceWindowWidth = windowWidth >= 1024 ? windowWidth : 1024
	let tranformScale = (1.00/zoomElement.outerWidth()) * ((zoomElement.outerWidth()/1440.00) * referenceWindowWidth)
	tranformScale = windowWidth >= 780 ? tranformScale : 0.8
	tranformScale = windowWidth >= 733 ? tranformScale : 1.0
	zoomElement.css("transform", `scale(${tranformScale})`)

	// Set height and width
	$(this.element).css("height", $(window).outerHeight())
	this.lastOuterWidth = $(window).outerWidth()

	// Hide & Reset
	$(this.element).find(".landing-area-container").hide()
	$(this.element).removeClass('initial-setup')

	// Load and Display
	$(this.element).imagesLoaded(() => {
	    let $draggable = $(this.element).find(".slide-dragger-container .slide-dragger").draggabilly({
		containment: ".slide-dragger-container",
		axis: "x"
	    })

	    $draggable.on('dragStart dragMove dragEnd', function(event, pointer) {
		var offset = $(this).offset()
		$(this).parents('.slide-dragger-container').siblings('.slide-solution').css("clip-path", `inset(0 0 0 ${offset.left + ($(this).outerWidth()/2.0)}px)`)
	    })

	    $(this.element).find(".landing-area-container").fadeIn(() => {
		$(this.element).addClass('initial-setup initial-animation')
		setTimeout(() => {
		    $(this.element).removeClass('initial-animation')
		}, 1750) // Time should be same as that required by the animation.
	    })
	})
    }

    resizer() {
	if (this.lastOuterWidth == $(window).outerWidth()) { return }
	this.lastOuterWidth = $(window).outerWidth()

	if (this.resizeTO) clearTimeout(this.resizeTO)
	this.resizeTO = setTimeout(() => {
	    this.builder()
	}, 500)
    }
}
