import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    create(container_element) {
	$(container_element).find('input[type="file"]').each((i, element) => {
	    if ($(element).siblings(".file-picker").length > 0) {
		return
	    }

	    if ($(element).data("filepicker")) {
		var style = $(element).data("filepicker")
	    } else {
		return
	    }

	    var filepickerElement = document.createElement('div')
	    $(filepickerElement).addClass('file-picker')
	    $(filepickerElement).addClass(`style-${style}`)

	    var imageTag = document.createElement('img')
	    $(filepickerElement).append(imageTag)
	    if ($(element).data('preview-url')) {
		imageTag.src = $(element).data('preview-url')
	    }

	    var inputTag = document.createElement('input')
	    inputTag.type = 'file'
	    if(element.getAttribute('title')) { inputTag.title = element.getAttribute('title') }
	    if(element.getAttribute('accept')) { inputTag.accept = element.getAttribute('accept') }
	    $(filepickerElement).prepend(inputTag)

	    $(inputTag).change((event) => {
		var selectedFile = event.target.files[0]

		if (window.FileReader) {
		    var reader = new FileReader()

		    if (reader && selectedFile && selectedFile.type.match(/image.*/)) {
			reader.onload = (readerEvent) => {

			    $(filepickerElement).data('filename', selectedFile.name)
			    $(filepickerElement).data('filetype', selectedFile.type)

			    if ($(element).data('cropper')) {
				this.cropper(filepickerElement, element, readerEvent.target.result, $(element).data('cropper'), style)
			    } else {
				imageTag.src = readerEvent.target.result
				let container = new DataTransfer()
				container.items.add(selectedFile)
				element.files = container.files
				filepickerElement.querySelector('input[type="file"]').dispatchEvent(new CustomEvent('input'))
			    }
			}
			reader.readAsDataURL(selectedFile)
		    }
		}
	    })

	    $(element).hide()
	    if($(element).next().length && $(element).next()[0].name.indexOf("[_destroy]") != -1) {
		var destroyElement = document.createElement('div')
		$(destroyElement).addClass("destroy")
		$(destroyElement).attr("data-title", "Remove")
		$(destroyElement).on("click", () => {
		    $(element).next().attr("value", "true")
		    $(filepickerElement).hide()
		})
		$(filepickerElement).append(destroyElement)
		$(filepickerElement).insertAfter($(element).next()[0])
	    } else {
		$(filepickerElement).insertAfter(element)
	    }
	})
    }

    destroy(container_element) {
	$(container_element).find('input[type="file"]').each((i, element) => {
	    if ($(element).siblings('.file-picker').length) {
		$(element).siblings('.file-picker').remove()
		$(element).show()
	    }
	})
    }

    cropper(filepickerElement, element, imageData, cutterSize, style) {
	// setup
	var image = document.createElement('img')
	image.src = imageData

	var imageCropperPosition = { 'left': $(filepickerElement).offset().left - 15, 'top': $(filepickerElement).offset().top - 5 }
	var imageCropper = document.createElement('div')
	$(imageCropper).hide().addClass('file-picker-image-cropper').css(imageCropperPosition)
	$(imageCropper).addClass(`style-${style}`)

	var canvas = document.createElement('canvas')
	var button = document.createElement('button'); $(button).html("APPLY")
	var canvasCutterBox = document.createElement('div'); $(canvasCutterBox).addClass('canvas-cutter-box')
	var canvasCutterImage = document.createElement('img'); $(canvasCutterImage).addClass('canvas-cutter-image')
	var canvasCutter = document.createElement('div'); $(canvasCutter).addClass('canvas-cutter')
	$(canvasCutterBox).append(canvasCutterImage)
	$(canvasCutterBox).append(canvasCutter)

	$(imageCropper).append(canvas, button, canvasCutterBox)

	$('body').append(imageCropper)
	var ctx = canvas.getContext("2d")

	// button
	$(button).click((event) => {
	    event.preventDefault()

	    var button_element = $(this)
	    $(imageCropper).fadeOut(function() {
		var cropLeft = parseInt($(canvasCutter).css('left'))
		var cropTop = parseInt($(canvasCutter).css('top'))
		var cropWidth = $(canvasCutter).width()
		var cropHeight = $(canvasCutter).height()

		// Resetting to original image size proportion before extracting the cropped area.
		cropLeft *= (image.width/canvas.width)
		cropTop  *= (image.height/canvas.height)
		cropWidth *= (image.width/canvas.width)
		cropHeight *= (image.height/canvas.height)

		canvas.width = cropWidth
		canvas.height = cropHeight

		ctx.drawImage(image, cropLeft, cropTop, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight)
		$(filepickerElement).find('img').attr('src', canvas.toDataURL())
		canvas.toBlob(function(blob) {
		    let file = new File([blob], $(filepickerElement).data("filename"), { type: $(filepickerElement).data("filetype"), lastModified: new Date().getTime() })
		    let container = new DataTransfer()
		    container.items.add(file)
		    element.files = container.files
		    filepickerElement.querySelector('input[type="file"]').dispatchEvent(new CustomEvent('input'))
		}, $(filepickerElement).data("filetype"))

		$(this).remove()
	    })
	})

	// Close image cropper on outside click.
	document.addEventListener('click', (event) => {
	    if (event && imageCropper.contains(event.target)) {
		return
	    }

	    $(imageCropper).fadeOut(function() {
		$(this).remove()
	    })
	})

	image.onload = () => {
	    var MAX_WIDTH = window.innerWidth - 30
	    var MAX_HEIGHT = window.innerHeight - 70
	    MAX_WIDTH = MAX_WIDTH >= 640 ? 640 : MAX_WIDTH

	    var width = image.width
	    var height = image.height

	    if (width > MAX_WIDTH) {
		height *= MAX_WIDTH / width
		width = MAX_WIDTH
	    }

	    if (height > MAX_HEIGHT) {
		width *= MAX_HEIGHT / height
		height = MAX_HEIGHT
	    }

	    // canvas
	    canvas.width = width
	    canvas.height = height
	    ctx.drawImage(image, 0, 0, width, height)
	    canvasCutterImage.src = canvas.toDataURL()

	    // canvasCutterBox
	    var imageCropperPaddingLeft = parseInt($(imageCropper).css('paddingLeft'))
	    var imageCropperPaddingTop = parseInt($(imageCropper).css('paddingTop'))

	    $(canvasCutterBox).css({
		'left': imageCropperPaddingLeft,
		'top': imageCropperPaddingTop,
		'width': canvas.width,
		'height': canvas.height
	    })

	    // canvasCutter
	    var cutterWidth = Array.isArray(cutterSize) ? cutterSize[0] : canvas.width - (canvas.width/4)
	    var cutterHeight = Array.isArray(cutterSize) ? cutterSize[1] : canvas.height - (canvas.height/4)
	    if (cutterWidth > canvas.width) {
		cutterHeight *= canvas.width / cutterWidth
		cutterWidth = canvas.width
	    }
	    if (cutterHeight > canvas.height) {
		cutterWidth *= canvas.height / cutterHeight
		cutterHeight = canvas.height
	    }

	    $(canvasCutter).outerWidth(cutterWidth)
	    $(canvasCutter).outerHeight(cutterHeight)

	    var canvasCutterLeft = (canvas.width - cutterWidth)/2
	    var canvasCutterTop = (canvas.height - cutterHeight)/2 - ((canvas.height - cutterHeight)/6)

	    var canvasCutterPosition = { 'left': canvasCutterLeft, 'top': canvasCutterTop }
	    $(canvasCutter).css(canvasCutterPosition)

	    this.canvasCutterImageClipper(canvas, canvasCutterImage, canvasCutter, style)

	    $(canvasCutter).draggable({
		containment: canvas,
		drag: (e, ui) => {
		    this.canvasCutterImageClipper(canvas, canvasCutterImage, canvasCutter, style)
		}
	    }).resizable({
		containment: canvas,
		aspectRatio: true,
		handles: 'all',
		resize: (e, ui) => {
		    this.canvasCutterImageClipper(canvas, canvasCutterImage, canvasCutter, style)
		}
	    })

	    imageCropper.style.display = "block"
	    imageCropper.style.opacity = 0
	    var imageCropperBCR = imageCropper.getBoundingClientRect()
	    var availableWidth = window.innerWidth - 15
	    var availableHeight = window.innerHeight - 5
	    if((imageCropperBCR.left + imageCropperBCR.width) > availableWidth) {
		$(imageCropper).css({ 'left': availableWidth - imageCropperBCR.width })
	    }
	    if((imageCropperBCR.top + imageCropperBCR.height) > availableHeight) {
		$(imageCropper).css({ 'top': window.scrollY + availableHeight - imageCropperBCR.height })
	    }
	    if(imageCropper.getBoundingClientRect().top < 0) {
		$(imageCropper).css({ 'top': 0 })
	    }
	    imageCropper.style.removeProperty("opacity")
	    imageCropper.style.removeProperty("display")

	    // Show imageCropper
	    $(imageCropper).fadeIn()
	}
    }

    canvasCutterImageClipper(canvas, canvasCutterImage, canvasCutter, style) {
	var canvasCutterLeft = parseInt($(canvasCutter).css('left'))
	var canvasCutterTop = parseInt($(canvasCutter).css('top'))
	var canvasCutterWidth = $(canvasCutter).outerWidth()
	var canvasCutterHeight = $(canvasCutter).outerHeight()

	if (style == 'circle') {
	    var clipRadius = canvasCutterWidth/2.0
	    var clipCenterFromLeft = canvasCutterLeft + canvasCutterWidth/2.0
	    var clipCenterFromTop = canvasCutterTop + canvasCutterHeight/2.0

	    $(canvasCutterImage).css('clip-path', `circle(${clipRadius}px at ${clipCenterFromLeft}px ${clipCenterFromTop}px)`)
	} else if (style == 'horizontal') {
	    var clipTop = canvasCutterTop
	    var clipLeft = canvasCutterLeft
	    var clipRight = canvas.width - (canvasCutterLeft + canvasCutterWidth)
	    var clipBottom = canvas.height - (canvasCutterTop + canvasCutterHeight)

	    $(canvasCutterImage).css('clip-path', `inset(${clipTop}px ${clipRight}px ${clipBottom}px ${clipLeft}px round 3px)`)
	} else {
	    var clipTop = canvasCutterTop
	    var clipLeft = canvasCutterLeft
	    var clipRight = canvas.width - (canvasCutterLeft + canvasCutterWidth)
	    var clipBottom = canvas.height - (canvasCutterTop + canvasCutterHeight)

	    $(canvasCutterImage).css('clip-path', `inset(${clipTop}px ${clipRight}px ${clipBottom}px ${clipLeft}px round 3px)`)
	}
    }
}
