import { ApplicationController } from "../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	initialize() {
		$(document).on('mouseup', (event) => {
			let container = $(this.element)

			if (!container.is(event.target) && container.has(event.target).length === 0) {
				if (container.hasClass('active')) {
					Rails.fire(container.find('a.back-button')[0], "click")
				}
			}
		})
	}

	openBulletin(event) {
		event.preventDefault()
		$(".system-bulletin").addClass("active").animateCss("slideInRight")
	}

	closeBulletin(event) {
		event.preventDefault()
		let systemBulletin = $(event.target).parents(".system-bulletin")
		systemBulletin.animateCss("slideOutRight", () => {
			systemBulletin.removeClass("active")
		})
	}

	closeBulletinBeforeCache(event) {
		$(".system-bulletin").removeClass("active")
	}

	destroyByDay(event) {
		event.preventDefault()
		let timer = 0
		$(event.target).closest('.single-day-messages').addClass('opacity-partial').find('.all-messages a.single-message .message-header img.close').each(function() {
			let that = $(this)
			setTimeout(() => {
				that.click()
			}, timer)
			timer = timer + 750
		})
	}

	destroy(event) {
		event.preventDefault()
		let destroyBtn = $(event.target).closest('.single-message-container').find('a.destroy-btn')
		Rails.fire(destroyBtn[0], "click")
	}
}