import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'
import moment from 'moment'

export default class extends ApplicationController {
	create(container_element) {
		let shortDatePickerSettings = {
			singleDatePicker: true,
			showDropdowns: true,
			locale: {
				format: 'ddd, D MMM YYYY'
			}
		}

		let datePickerSettings = {
			singleDatePicker: true,
			showDropdowns: true,
			locale: {
				format: 'dddd, D MMMM YYYY'
			}
		}

		let timePickerSettings = {
			singleDatePicker: true,
			showDropdowns: true,
			timePicker: true,
			timePickerSeconds: true,
			locale: {
				format: 'LTS'
			}
		}

		let dateTimePickerSettings = {
			singleDatePicker: true,
			showDropdowns: true,
			timePicker: true,
			timePickerSeconds: true,
			locale: {
				format: 'dddd, D MMMM YYYY [at] LTS'
			}
		}

		let dateRangePickerSettings = {
			locale: {
				format: 'dddd, D MMMM YYYY'
			}
		}

		let dateTimeRangePickerSettings = {
			timePicker: true,
			timePickerSeconds: true,
			locale: {
				format: 'dddd, D MMMM YYYY [at] LTS'
			}
		}

		let inputSelectors = 'input[pickertype="shortdate"], input[pickertype="date"], input[pickertype="time"], input[pickertype="datetime"], input[pickertype="daterange"], input[pickertype="datetimerange"]'
		let inputDatePickerElements = $(container_element).find(inputSelectors)

		inputDatePickerElements.each((i, element) => {
			$(element).attr('autocomplete', 'off')

			let pickerType = $(element).attr('pickertype')

			let pickerSettings = {
			    autoUpdateInput: false,
			    locale: {
				cancelLabel: 'Clear'
			    }
			}

			if (pickerType == "shortdate") {
				pickerSettings = _.merge(pickerSettings, shortDatePickerSettings)
			} else if (pickerType == "date") {
				pickerSettings = _.merge(pickerSettings, datePickerSettings)
			} else if (pickerType == "time") {
				pickerSettings = _.merge(pickerSettings, timePickerSettings)
			} else if (pickerType == "datetime") {
				pickerSettings = _.merge(pickerSettings, dateTimePickerSettings)
			} else if (pickerType == "daterange") {
				pickerSettings = _.merge(pickerSettings, dateRangePickerSettings)
			} else if (pickerType == "datetimerange") {
				pickerSettings = _.merge(pickerSettings, dateTimeRangePickerSettings)
			}

			if (element.hasAttribute("max")) {
				pickerSettings = _.merge(pickerSettings, { maxDate: moment($(element).attr("max")) })
			}

			if (element.hasAttribute("min")) {
				pickerSettings = _.merge(pickerSettings, { minDate: moment($(element).attr("min")) })
			}

			$(element).daterangepicker(pickerSettings)

			if (element.hasAttribute("value") && $(element).attr("value")) {
				var picker = $(element).data("daterangepicker")

				var dates = $(element).attr("value").split(' - ')
				var allowed_dates_formats = [picker.locale.format, "YYYY-MM-DD HH:mm:ss Z"]
				var startDate = moment(dates[0], allowed_dates_formats)
				var endDate = dates[1] ? moment(dates[1], allowed_dates_formats) : moment(dates[0], allowed_dates_formats)

				picker.setStartDate(startDate)
				picker.setEndDate(endDate)

				if (picker.singleDatePicker) {
					$(element).val(picker.startDate.format(pickerSettings.locale.format))

					// If stretchy, resize field after changing its value
					if ($(element).hasClass("stretchy")) { Stretchy.resize($(element)[0]) }
				} else {
					$(element).val(picker.startDate.format(pickerSettings.locale.format) + ' - ' + picker.endDate.format(pickerSettings.locale.format))

					// If stretchy, resize field after changing its value
					if ($(element).hasClass("stretchy")) { Stretchy.resize($(element)[0]) }
				}
			}
		})

		inputDatePickerElements.on('show.daterangepicker', function (ev, picker) {
		    if (picker.element.attr("pickertype") == "time") {
			picker.container.addClass("show-onlytime");
		    }
		})

		inputDatePickerElements.on('apply.daterangepicker', function (ev, picker) {
			var startDate = picker.startDate.format(picker.locale.format)
			var endDate = picker.endDate.format(picker.locale.format)
			picker.singleDatePicker ? $(this).val(startDate) : $(this).val(`${startDate} - ${endDate}`)

			// If stretchy, resize field after changing its value
			if ($(this).hasClass("stretchy")) { Stretchy.resize($(this)[0]) }

			// If form-fire is set, fire on form of the field
			if ($(this).data("form-fire")) {
				var form_element = $(this).closest('form')[0]
				Rails.fire(form_element, $(this).data("form-fire"))
			}
		})

		inputDatePickerElements.on('cancel.daterangepicker', function (ev, picker) {
			var startDate = moment().startOf('day')
			var endDate = moment().endOf('day')

			picker.setStartDate(startDate)
			picker.setEndDate(endDate)

			$(this).val('')

			// If form-fire is set, fire on form of the field
			if ($(this).data("form-fire")) {
				var form_element = $(this).closest('form')[0]
				Rails.fire(form_element, $(this).data("form-fire"))
			}
		})
	}
}
