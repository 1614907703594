import { ApplicationController } from "../application_controller"

export default class extends ApplicationController {
    static targets = []

    initialize() {
	this.setBackgroundImages()
    }

    connect() {}

    disconnect() {}

    setBackgroundImages() {
	let backgroundImageElements = this.element.querySelectorAll('[data-background="true"]')
	backgroundImageElements.forEach((backgroundImageElement) => {
	    let backgroundElement = backgroundImageElement.closest('[data-background-target="true"]')
	    let src = backgroundImageElement.getAttribute("src")
	    if(backgroundElement && src) {
		backgroundElement.style.backgroundImage = "url('" + src + "')"
		backgroundImageElement.style.display = "none"
	    }
	})
    }
}
