import { ApplicationController } from "../../application_controller"
import ConfirmationDialogController from "../helpers/confirmation_dialog_controller"

export default class extends ApplicationController {
	new(event) {
		event.preventDefault()
		$(event.target).parents('.page').find('.section-form .add-button').click()
	}

	confirmDestroy(event) {
		let goto_url = localStorage.getItem(`goto-for-${window.location.pathname}`)
		if (goto_url) {
			let url = $(event.currentTarget).attr('href')
			if (goto_url != '') { !url.match(/goto_url=/g) ? (url += (url.match(/[\?]/g) ? '&' : '?') + `goto_url=${goto_url}`) : '' }
			$(event.currentTarget).attr('href', url)
		}

		var confirmation_dialog_controller = new ConfirmationDialogController()
		confirmation_dialog_controller.show(event, false)
	}
}