import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
	showLoader(event) {
		var section_helper = $(this.element).find('.section-helper')
		var loading_note = $(this.element).find('.loading-note')
		if (section_helper.length && loading_note.length) {
			loading_note.css('top', section_helper.outerHeight())
		}
		$(this.element).addClass('loading')
	}

	showLoaderWithoutRedirect(event) {
	    if (event.target.enctype === 'multipart/form-data') {
		event.detail[1].data.append("redirect", "false")
	    } else {
		event.detail[1].data = event.detail[1].data + "&redirect=false"
	    }
	    this.showLoader()
	}
}
