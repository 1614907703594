import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    initialize() {}

    connect() {}

    onScroll() {
	if ($(this.element).find('a').length && ($(window).scrollTop() > ($(document).height() - $(window).height() - 1200))) {
	    let loadMoreButton = $(this.element).find('a')
	    Rails.fire(loadMoreButton[0], "click")
	}
    }
}
