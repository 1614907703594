import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() {}

    open(event) {
	event.preventDefault()

	if($(event.currentTarget).closest('.faq').hasClass("opened")) {
	    this.closePreviouslyOpened()
	    return
	}

	this.closePreviouslyOpened()

	$(event.currentTarget).closest('.faq').find('.answer').slideDown('fast', function() {
	    $(this).closest(".faq").addClass('opened')
	})
    }

    closePreviouslyOpened() {
	$(this.element).find('.faq.opened').find('.answer').slideUp('fast', function() {
	    $(this).closest(".faq").removeClass("opened")
	})
    }
}
