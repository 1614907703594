import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() { }

    connect() { }

    disconnect() { }

    play(event) {
	event.preventDefault()
	let player = this.element.querySelector('[data-target="player"]')
	let player_screen = player.querySelector('[data-target="player-screen"]')
	let video = player_screen.querySelector("video")

	video.play()
	player.classList.add('playing')
    }

    stop(event) {
	event.preventDefault()
	let player = this.element.querySelector('[data-target="player"]')
	let player_screen = player.querySelector('[data-target="player-screen"]')
	let video = player_screen.querySelector("video")

	video.pause()
	video.currentTime = 0
	player.classList.remove('playing')	
    }
}
