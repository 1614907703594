$(document).on('turbolinks:load', function() {
	// Scroll page sections
	var $root = $('html, body');
	$('.scroll-page').click(function() {
	    $root.animate({
	        scrollTop: $( $.attr(this, 'href') ).offset().top - 0
	    }, 500);
	    return false;
	});

	if($('.about-page').length) {
		// Move the infographic down in the about page
		offset_top = $('.about-page #section-3 .wrapper .right-side').offset().top - 100;
		offset_bottom = $('.about-page #section-3 .wrapper .right-side').offset().top + $('.about-page #section-3 .wrapper .right-side').height() - $('.about-page #section-3 .wrapper .left-side figure').height() - 200;
		$(document).scroll(function() {
			var scroll = $(this).scrollTop();
			if ((scroll >= offset_top) && (scroll <= offset_bottom)) {
				$('.about-page #section-3 .wrapper .left-side figure').css('margin-top', (scroll - offset_top + 25));
			}
		});
	}

	// public header menu button
	$('header.public-header .top-header .menu-items .menu-button a').click(function() {
		$('.public-header .top-header .menu-items menu').toggleClass('active');
		$(this).toggleClass('active');
		return false
	});
	
	$('.homepage-ecommerce .landing-section').css('min-height', $(window).height());

	if($('.homepage-ecommerce .contact-button').length) {
		$(window).scroll(function() {
		    if ($(this).scrollTop() > ($('body').height() - $('footer').height() - 800)) { //use `this`, not `document`
		        $('.homepage-ecommerce .contact-button').fadeOut();
		    } else {
		    	$('.homepage-ecommerce .contact-button').fadeIn();
		    }
		});
	}
});

$(document).on('click', '.homepage-ecommerce .contact-button a.contact-button-click', function() {
	$(this).siblings('.display-area').fadeToggle();
	return false;
});

$(document).on("ajax:beforeSend", ".homepage-ecommerce form[data-remote].action-form", function(e, data, status, xhr) {
	form_element = $(this);
	form_element.find('input[type="submit"]').addClass('inactive');
	form_element.find('.ajax-loader').addClass('active');
});

$(document).on("ajax:complete", ".homepage-ecommerce form[data-remote].action-form", function(e, xhr, status, error) {
	form_element = $(this);
	form_element.find('input[type="submit"]').removeClass('inactive');
	form_element.find('.ajax-loader').removeClass('active');	
});
