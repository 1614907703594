import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	static targets = [ "cartButton", "profileButton" ]

	initialize() {}

	openCart(event) {
	    if (this.geoLocationCompleted()) {
		this.cartsController.open(event)
		this.cartsController.refresh(event)
	    } else {
		event.preventDefault()
		Rails.fire($('[data-controller="marketing--geolocations--buttons"] .geolocation-btn')[0], 'click')
	    }
	}

	closeCart(event) {
	    if (this.geoLocationCompleted()) {
		if (this.cartButtonTarget.contains(event.target)) { return }

		this.cartsController.close(event)
	    }
	}

	openProfile(event) {
	    if (this.profilesController) {
		this.profilesController.open(event)
	    }
	}

	closeProfile(event) {
	    if (this.profileButtonTarget.contains(event.target)) { return }

	    if (this.profilesController) {
		this.profilesController.close(event)
	    }
	}

	get profilesController() {
		return this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="marketing--profiles"]'), "marketing--profiles")
	}

	get cartsController() {
		return this.application.getControllerForElementAndIdentifier(document.getElementsByClassName("cart")[0], "marketing--carts")
	}

	geoLocationCompleted() {
	    var geolocationElement = document.querySelector("#geolocation")
	    if (geolocationElement) {
		if (geolocationElement.classList.contains('geolocation-completed')) {
		    return true
		} else {
		    return false
		}
	    } else {
		return true
	    }
	}
}
