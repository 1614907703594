import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() {
	this.clearUnncessaryGotoUrlsInLocalStorage()
    }

    clearUnncessaryGotoUrlsInLocalStorage() {
	var subPaths = this.findSubPaths(window.location.pathname).join('|')

	var needed_key_pattern = new RegExp(`^goto-for-(${subPaths})`)
	var goto_for_keys_to_remove = Object.keys(localStorage).filter((key) => /^goto-for-/.test(key) && !needed_key_pattern.test(key))

	goto_for_keys_to_remove.forEach(function(key) {
	    localStorage.removeItem(key)
	})
    }

    findSubPaths(path) {
	var paths = []
	for (var i = 1; i < path.length; i++) {
	    if (path[i] === '/') paths.push(path.substr(0,i))
	}
	paths.push(window.location.pathname)

	return paths
    }

    oracle(event) {
	event.currentTarget.querySelector("a.oracle").click()
    }
}
