import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
    initialize() { }

    connect() {
	this.scrollMapper()
    }

    disconnect() { }

    scrollMapper() {
	$(window).scroll(() => {
	    let addScrollingAt = 50
	    if ($(window).scrollTop() > addScrollingAt) {
		$(this.element).addClass("scrolling")
	    } else {
		$(this.element).removeClass("scrolling")
	    }
	})
    }
}
