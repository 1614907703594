import { ApplicationController } from "../../application_controller"
import { EditorState, basicSetup } from "@codemirror/basic-setup"
import { EditorView, keymap } from "@codemirror/view"
import { indentUnit } from "@codemirror/language"
import { oneDark } from "@codemirror/theme-one-dark"
import { foldAll, foldEffect } from "@codemirror/fold"
import { defaultTabBinding } from "@codemirror/commands"
import { html } from "@codemirror/lang-html"
import { css } from "@codemirror/lang-css"
import { javascript } from "@codemirror/lang-javascript"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    create(container_element) {
	$(container_element).find('textarea').each((i, element) => {
	    if ($(element).data('codeEditor') == true) {
		let codeView = this.editorFromTextArea(element, $(element).data('language'))
		$(element).data('codeView', codeView)
	    }
	})
    }

    destroy(container_element) {
	$(container_element).find('textarea').each((i, element) => {
	    if ($(element).siblings('.cm-editor').length) {
		element.style.display = "block"
		$(element).data('codeView').destroy()
	    }
	})
    }

    editorFromTextArea(textarea_element, language) {
	let saveEditorCodeKeyMapping = {
	    key: "Mod-s",
	    run: ({ state, dispatch }) => {
		var url = new URL(textarea_element.form.action)
		url.searchParams.set('redirect', "false")
		textarea_element.form.action = url.href
		Rails.fire(textarea_element.form, "submit")

		return true
	    }
	}

	let extensions = [basicSetup, oneDark, keymap.of([defaultTabBinding, saveEditorCodeKeyMapping]), indentUnit.of("	")]

	if(language == "html") {
	    extensions.push(html())
	} else if (language == "css") {
	    extensions.push(css())
	} else if (language == "javascript") {
	    extensions.push(javascript())
	}

	let startState = EditorState.create({
	    doc: textarea_element.value,
	    extensions: extensions
	})
	
	let codeView = new EditorView({
	    state: startState
	})

	// This can be used to foldAll the code.
	// The syntax highlighting of the code does not work properly if this is used on initial load. So don't use it on initial load of the code editor.
	// foldAll(codeView)

	// This node should be inserted after the textarea element as per the convention followed everywhere else.
	// Implement when possible.
	textarea_element.parentNode.insertBefore(codeView.dom, textarea_element)
	textarea_element.style.display = "none"

	// This event listener does not get destroyed anywhere after creation and the responsibility is left to the browser.
	// If this becomes a problem, go ahead and implement when needed.
	if (textarea_element.form) textarea_element.form.addEventListener("submit", () => {
	    textarea_element.value = codeView.state.doc.toString()
	})

	// The button element by default submits the form and does not allow code editor searching to work properly inside a form.
	// The below fix makes the button element not submit the form and fixes the problem.
	this.changeButtonTypeOnCodeSearch(textarea_element.previousSibling)

	return codeView
    }

    changeButtonTypeOnCodeSearch(targetNode) {
	// Options for the observer (which mutations to observe)
	const config = { attributes: true, childList: true, subtree: true }

	// Callback function to execute when mutations are observed
	const callback = function(mutationsList, observer) {
	    // Use traditional 'for loops' for IE 11
	    for(const mutation of mutationsList) {
		if (mutation.type === 'childList') {
		    let buttonList = mutation.target.querySelectorAll('.cm-search button.cm-button')
		    for(const button of buttonList) {
			button.type = "button"
		    }
		    // console.log('A child node has been added or removed.')
		}
	    }
	}

	// Create an observer instance linked to the callback function
	const observer = new MutationObserver(callback)

	// Start observing the target node for configured mutations
	observer.observe(targetNode, config)

	// This observer does not get destroyed anywhere after creation and the responsibility is left to the browser.
	// If this becomes a problem, go ahead and implement when needed.
	// Later, you can stop observing
	// observer.disconnect()
    }
}
