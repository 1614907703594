import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    static targets = [ "searchForm", "searchInput", "searchButton", "clearButton", "suggestionsWorker", "suggestions" ]

    initialize() {
	// Use this only when throttle is needed.
	// this.work = _.throttle(this.work, 1000)
    }

    connect() {}

    destroy() {}

    submitter(event) {
	if (!this.searchInputTarget.value.trim()) {
	    event.preventDefault()
	} else {
	    this.searchButtonTarget.disabled = true
	}
    }

    suggester() {
	if (this.searchInputTarget.value) {
	    this.clearButtonTarget.classList.add("active")
	    this.suggestionsTarget.classList.add("active")
	    this.updateSuggestionsWorkerUrl("suggest", this.searchInputTarget.value)
	    this.work()
	} else {
	    this.clearButtonTarget.classList.remove("active")
	    this.suggestionsTarget.classList.remove("active")	   
	}
    }

    clearInput() {
	this.searchInputTarget.value = ""
	this.searchInputTarget.dispatchEvent(new Event("input"))
    }

    navigationKeys(event) {
	event = event || window.event

	var selectedElement = this.suggestionsTarget.querySelector(".selected")
	var firstElement = this.suggestionsTarget.querySelector("a:first-child")
	var lastElement = this.suggestionsTarget.querySelector("a:last-child")

	var keyCode = event.keyCode || event.which
	if(keyCode == '38') { // Up
	    event.preventDefault()

	    if(selectedElement && selectedElement.previousSibling) {
		selectedElement.previousSibling.classList.add("selected")
		selectedElement.classList.remove("selected")
		selectedElement = this.suggestionsTarget.querySelector(".selected")
	    } else {
		if(selectedElement) {
		    selectedElement.classList.remove("selected")
		}
		if(lastElement) {
		    lastElement.classList.add("selected")
		}
	    }

	    selectedElement = this.suggestionsTarget.querySelector(".selected")
	    this.searchInputTarget.value = selectedElement.dataset.value
	} else if(keyCode == '40') { // Down
	    event.preventDefault()

	    if(selectedElement && selectedElement.nextSibling) {
		selectedElement.nextSibling.classList.add("selected")
		selectedElement.classList.remove("selected")
		selectedElement = this.suggestionsTarget.querySelector(".selected")
	    } else {
		if(selectedElement) {
		    selectedElement.classList.remove("selected")
		}
		if(firstElement) {
		    firstElement.classList.add("selected")
		}
	    }

	    selectedElement = this.suggestionsTarget.querySelector(".selected")
	    this.searchInputTarget.value = selectedElement.dataset.value
	}
    }

    onInputFocus(event) {
	this.element.classList.add("focused")
    }

    onInputBlur(event) {
	this.element.classList.remove("focused")
    }

    suggestionMouseover(event) {
	var selectedElement = this.suggestionsTarget.querySelector(".selected")
	if(selectedElement) {
	    selectedElement.classList.remove("selected")
	}
	event.currentTarget.classList.add("selected")
    }

    suggestionClicked(event) {
	event.preventDefault()

	var selectedElement = this.suggestionsTarget.querySelector(".selected")
	this.searchInputTarget.value = selectedElement.dataset.value
	this.searchFormTarget.submit()
    }

    /* ***** Helpers ***** */

    getWorkerUrl() {
	return (new URL(this.suggestionsWorkerTarget.href))
    }

    work() {
	Rails.fire(this.suggestionsWorkerTarget, 'click')
    }

    updateSuggestionsWorkerUrl(paramName, paramValue) {
	var url = this.getWorkerUrl()
	url.searchParams.set(paramName, paramValue)
	this.suggestionsWorkerTarget.href = url
    }
}
