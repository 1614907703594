import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
    create(container_element) {
	let selectizeOptions = {
	    onInitialize: function() {
		var obj = $(this)[0]
		var select_element = obj.$input["0"]
		if (!$(select_element).data('form-refresh')) {
		    this.trigger('change', this.getValue(), true);
		}
	    },
	    onChange: function(value) {
		var obj = $(this)[0]
		var select_element = obj.$input["0"]

		if ($(select_element).data('form-refresh')) {
		    var form_element = $(select_element).parents('form')[0]
		    var url = new URL(form_element.action)
		    url.searchParams.set('form_refresh', true)
		    form_element.action = url.href
		    Rails.fire(form_element, "submit")
		}

		if ($(select_element).data('form-fire')) {
		    var form_element = $(select_element).closest('form')[0]
		    Rails.fire(form_element, $(select_element).data('form-fire'))
		}

		var price_after_discount = this.revertSettings.$children.filter(`option[value='${value}']`).data('price-after-discount')
		var price = this.revertSettings.$children.filter(`option[value='${value}']`).data('price')
		var discount = this.revertSettings.$children.filter(`option[value='${value}']`).data('discount')
		var hasDiscount = (price == price_after_discount) ? false : true

		if (price_after_discount) { $(select_element).closest('[data-controller="marketing--products--variations"]').parent().find('[data-target="price-after-discount"]').html(price_after_discount) }
		if (price) {
		    var priceElement = $(select_element).closest('[data-controller="marketing--products--variations"]').parent().find('[data-target="price"]')
		    priceElement.html(price)
		    if(!hasDiscount) {
			priceElement.addClass("inactive")
		    } else {
			priceElement.removeClass("inactive")
		    }
		}
		if (discount) {
		    var discountElement = $(select_element).closest('[data-controller="marketing--products--variations"]').parent().find('[data-target="discount"]')
		    discountElement.html(discount)
		    if(!hasDiscount) {
			discountElement.addClass("inactive")
		    } else {
			discountElement.removeClass("inactive")
		    }
		}

		var quantity = this.revertSettings.$children.filter(`option[value='${value}']`).data('quantity')
		if (typeof quantity !== 'undefined') {
		    var quantity_element = $(select_element).closest('[data-controller="marketing--products--variations"]').parent().find('.variation-quantity-changer input.quantity')[0]

		    $(quantity_element).attr("data-product-id", $(select_element).attr('data-product-id'))
		    $(quantity_element).attr("data-variation-id", value)
		    quantity_element.value = quantity

		    setTimeout(() => {
			Rails.fire(quantity_element, "change")
		    }, 0)
		}

		var stock_quantity = this.revertSettings.$children.filter(`option[value='${value}']`).data('stock-quantity')
		if (typeof stock_quantity !== 'undefined') {
		    var quantity_changer_element = $(select_element).closest('[data-controller="marketing--products--variations"]').parent().find('.variation-quantity-changer')[0]
		    $(quantity_changer_element).find('.in-stock span').html(parseInt(stock_quantity))
		    $(quantity_changer_element).removeClass('less-stock')
		    if (stock_quantity == 0) {
			$(quantity_changer_element).addClass('no-stock')
		    } else {
			$(quantity_changer_element).removeClass('no-stock')

			// This will also be false when stock_quantity value is 'nil' to ensure it is hidden for unmaintained stock items.
			if (stock_quantity <= 3) {
			    $(quantity_changer_element).addClass('less-stock')
			} else {
			    $(quantity_changer_element).removeClass('less-stock')
			}
		    }

		    if (typeof quantity !== 'undefined') {
			var quantity_element = $(select_element).closest('[data-controller="marketing--products--variations"]').parent().find('.variation-quantity-changer input.quantity')[0]
			$(quantity_element).attr("data-variation-stock", stock_quantity)
		    }
		}

		// Variation Varieties
		if(select_element.getAttribute("name") == "variation_id") {
		    var varietiesElement = $(select_element).closest('[data-controller="marketing--products--variations"]').find('.product-variations-varieties')
		    if (varietiesElement.length) {
			var varietySelectElement = varietiesElement.find(`.product-variations-variety.variation_${value}_variety_id`)
			if(varietySelectElement.length) {
			    varietiesElement.addClass("active")
			    varietiesElement.find('.product-variations-variety').removeClass("active")
			    varietySelectElement.closest(".product-variations-variety").addClass("active")
			} else {
			    varietiesElement.find('.product-variations-variety').removeClass("active")
			    varietiesElement.removeClass("active")
			}

			var quantity_changer_element = $(select_element).closest('[data-controller="marketing--products--variations"]').parent().find('.variation-quantity-changer')[0]
			if (varietiesElement.hasClass('active')) {
			    var varietyBookingActiveSelectElement = varietiesElement.find(`.product-variations-variety.variation_variety_booking.active select`)
			    if(varietyBookingActiveSelectElement.length && varietyBookingActiveSelectElement.val() == "unavailable") {
				$(quantity_changer_element).addClass('no-variety-booking-dates')
			    } else {
				$(quantity_changer_element).removeClass('no-variety-booking-dates')
			    }
			} else {
			    $(quantity_changer_element).removeClass('no-variety-booking-dates')
			}
		    }
		}

		// Product Incentives
		// Razorpay Affordability Widget
		var price_after_discount_paise = this.revertSettings.$children.filter(`option[value='${value}']`).data('price-after-discount-paise')
		var incentiveRazorpayAffordabilityWidgetElement = $('[data-controller="marketing--pages"] [data-controller="marketing--products--incentives"] .product-incentive.razorpay-affordability-widget-container').get(0)
		if((typeof price_after_discount_paise !== 'undefined') && price_after_discount_paise > 0 && incentiveRazorpayAffordabilityWidgetElement) {
		    var rzpElement = document.createElement("div")
		    rzpElement.setAttribute("id", "razorpay-affordability-widget")
		    incentiveRazorpayAffordabilityWidgetElement.replaceChildren(rzpElement)

		    const rzpAffordabilitySuite = new RazorpayAffordabilitySuite({
			"key": incentiveRazorpayAffordabilityWidgetElement.getAttribute('data-key'),
			"amount": price_after_discount_paise,
			"theme": {
			    "color": "#ffb302"
			},
			"display": {
			    "widget": {
				"main": {
				    "heading": {
					"color": "#ffffff",
					"fontSize": "12px"
				    },
				    "content": {
					//"backgroundColor": "#e6f0ff",
					"color": "#ffffff",
					"fontSize": "10px"
				    },
				    "discount": {
					"color": "#ffb302"
				    },
				    "link": {
					"button": true,
					"color": "#ffb302",
					"fontSize": "12px"
				    },
				    "footer": {
					"color": "#ffffff",
					"fontSize": "10px",
					"darkLogo": false
				    },
				    "isDarkMode": false
				}
			    }
			}
		    })
		    rzpAffordabilitySuite.render()

		    setTimeout(() => {
			incentiveRazorpayAffordabilityWidgetElement.classList.add('active')
		    }, 500)
		} else if(incentiveRazorpayAffordabilityWidgetElement) {
		    incentiveRazorpayAffordabilityWidgetElement.classList.remove('active')
		}
	    },
	    render: {
		item: function(data, escape) {
		    var self = this
		    var field_label = self.settings.labelField
		    var sku = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('sku')
		    var booking_begin_at = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('booking-begin-at')
		    var booking_end_at = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('booking-end-at')
		    var imageUrl = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('image-url')

		    var item = `<div class="item"`
		    if(sku) { item += ` data-sku="${sku}"` }
		    if(booking_begin_at) { item += ` data-booking-begin-at="${booking_begin_at}"` }
		    if(booking_end_at) { item += ` data-booking-end-at="${booking_end_at}"` }
		    item += `>`
		    if (imageUrl) { item += `<img src="${imageUrl}">` }
		    item += escape(data[field_label])
		    item += '</div>'

		    return item
		},
		option: function(data, escape) {
		    var self = this
		    var field_label = self.settings.labelField
		    var sku = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('sku')
		    var booking_begin_at = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('booking-begin-at')
		    var booking_end_at = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('booking-end-at')
		    var imageUrl = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('image-url')
		    var subline = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('subline')
		    var priceAfterDiscount = this.revertSettings.$children.filter(`option[value='${data.value}']`).data('price-after-discount')

		    var option = `<div class="option"`
		    if(sku) { option += ` data-sku="${sku}"` }
		    if(booking_begin_at) { option += ` data-booking-begin-at="${booking_begin_at}"` }
		    if(booking_end_at) { option += ` data-booking-end-at="${booking_end_at}"` }
		    option += `>`
		    if (imageUrl) { option += `<img src="${imageUrl}">` }
		    option += escape(data[field_label])
		    if (priceAfterDiscount) { option += ` - ${priceAfterDiscount}` }
		    if (subline) { option += `<span class="subline">${subline}</span>` }
		    option += '</div>'

		    return option
		}
	    }
	}

	$(container_element).find('select').each((i, element) => {
	    if ($(element).data('allow-create')) {
		selectizeOptions["create"] = true
	    }

	    // Ensures element is not selectized twice.
	    if (!element.selectize) {
		$(element).selectize(selectizeOptions)
		$(element)[0].selectize.refreshOptions(false); // populate options on load without triggering the dropdown.
	    }

	    // Needed for Chrome browsers...Anger...Peace.
	    $(element)[0].selectize.$control_input.attr("autocomplete", "disabled")
	})
    }

    destroy(container_element) {
	$(container_element).find('select').each((i, element) => {
	    if (element.selectize) {
		var value = $(element).val()
		element.selectize.destroy()
		$(element).val(value)
	    }
	})
    }
}
