import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {

	collapseToggle(event) {
		event.preventDefault()

		let sectionHeader = $(event.target).parents('.section-header')
		sectionHeader = sectionHeader.length ? sectionHeader : $(event.target)

		this.collapseExec(sectionHeader)
	}

	collapseExec(sectionHeader) {
		if (sectionHeader.find("a.collapse").length) {
			var sectionContent = sectionHeader.closest(".page-section").find(".section-content")
			sectionContent.slideToggle('fast', function() {
				$(this).toggleClass('collapsed')
			})
			sectionHeader.find("a.collapse").toggleClass('collapsed')
		}
	}
}