import { ApplicationController } from "../../application_controller"

export default class extends ApplicationController {
	static targets = [ "term" ]

	initialize() {
		this.work = _.throttle(this.work, 1000)
	}

	focus() {
		$(this.element).find('input[type="text"]').focus()
	}

	work(event) {
		this.helperController().runSearch($(this.termTarget).val())
	}

	complete(paramValue) { }

	clear() {
		$(this.element).find('input[type="text"]').val('')
		$(this.element).find('input[type="text"]')[0].dispatchEvent(new Event('input'))
	}

	/* ***** Helpers ***** */

	helperController() {
		var section_helper = $(this.element).closest(".section-helper")
		return this.application.getControllerForElementAndIdentifier(section_helper[0], 'layouts--section--section-helper')
	}
}