import { ApplicationController } from "../../application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	static targets = [ "actions", "worker" ]

	toggleSearch(event) {
		event.preventDefault()

		$(this.element).toggleClass('filter-active search-active')

		if ($(this.element).hasClass('search-active')) {
			this.searchController().focus()
		} else {
			this.searchController().clear()
		}		
	}

	work() {
		Rails.fire(this.workerTarget, 'click')
	}

	complete() {
		var url = this.getWorkerUrl()
		var params = url.searchParams
		this.filterController().completeFilter(params.get('filter'))
		this.filterController().completePeriod(params.get('start_at'), params.get('end_at'))
		this.searchController().complete(params.get('search'))
	}

	runPeriod(startDate, endDate) {
		this.updateWorkerUrl('start_at', startDate)
		this.updateWorkerUrl('end_at', endDate)
		this.work()
	}

	runFilter(term) {
		this.updateWorkerUrl('filter', term)
		this.work()
	}

	runSearch(term) {
		this.updateWorkerUrl('search', term)
		this.work()
	}

        runExport() {
	    event.preventDefault()
	    var url = this.getWorkerUrl()
	    url.searchParams.set("format", "csv")
	    window.location.href = url
	}
	/* ***** Helpers ***** */

	getWorkerUrl() {
		return (new URL(this.workerTarget.href))
	}

	updateWorkerUrl(paramName, paramValue) {
		var url = this.getWorkerUrl()
		url.searchParams.set(paramName, paramValue)
		this.workerTarget.href = url
	}

	filterController() {
		var section_helper_filter = $(this.element).find(".section-helper-filter")
		return this.application.getControllerForElementAndIdentifier(section_helper_filter[0], 'layouts--section--section-helper-filter')
	}

	searchController() {
		var section_helper_search = $(this.element).find(".section-helper-search")
		return this.application.getControllerForElementAndIdentifier(section_helper_search[0], 'layouts--section--section-helper-search')
	}
}
