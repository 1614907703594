import { ApplicationController } from "../../application_controller"
import StretchyController from "../../layouts/helpers/stretchy_controller"
import SelectizeController from "../../layouts/helpers/selectize_controller"
import DaterangepickerController from "../../layouts/helpers/daterangepicker_controller"

export default class extends ApplicationController {
    initialize() {
	this.refreshAddMore()

	this.refreshDestroyListeners()
    }

    refreshField(event) {
	let input_destroy = event.currentTarget.querySelector("input[name*='[_destroy]']")
	if(input_destroy.value == "true") {
	    if (event.currentTarget.classList.contains('using-add-more')) {
		event.currentTarget.remove()
	    } else {
		event.currentTarget.classList.add("removed")
	    }

	    this.refreshAddMore()
	}
    }

    addMore(event) {
	event.preventDefault()

	// Get the link element
	let link = event.currentTarget
	// Save a unique timestamp to ensure the key of the associated array is unique.
	let time = new Date().getTime()
	// Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
	let linkId = link.dataset.id
	// Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
	let regexp = linkId ? new RegExp(linkId, 'g') : null
	// Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
	let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
	// Add the new markup to the form if there are fields to add.
	newFields ? link.parentNode.insertAdjacentHTML('beforebegin', newFields) : null
	// Defining these fields were added using add more button.
	link.parentNode.previousElementSibling.classList.add('using-add-more')
	// Stretchy the new element
	var stretchy_controller = new StretchyController()
	stretchy_controller.create(link.parentNode.previousElementSibling)
	// Selectize the new element
	var selectize_controller = new SelectizeController()
	selectize_controller.create(link.parentNode.previousElementSibling)
	// Datepickerize the new element
	var daterangepicker_controller = new DaterangepickerController()
	daterangepicker_controller.create(link.parentNode.previousElementSibling)
	// Refresh Destroy Listeners
	this.refreshDestroyListeners()
	// Show/Hide AddMore
	this.refreshAddMore()
    }

    refreshAddMore() {
	let link = this.element.querySelector('a.add-more')
	if(!link) return

	let count = this.element.querySelectorAll('.variety-fields .field').length
	let limit = parseInt(link.dataset.limit) + 1 // count is field count including the add-more field, hence the + 1 to the limit

	if(count >= limit) {
	    link.parentNode.classList.add('removed')
	} else {
	    link.parentNode.classList.remove('removed')
	}
    }

    refreshDestroyListeners() {
	$(this.element.querySelectorAll(".variety-fields .field .destroy")).off("click").on("click", (event) => {
	    $(event.currentTarget).prev().attr("value", "true")
	    $(event.currentTarget).closest(".field").hide()
	})
    }
}
