import { ApplicationController } from "../../application_controller"
import SelectizePublicController from "../../layouts/helpers/selectize_public_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
	initialize() {}

	connect() {
		var selectize_public_controller = new SelectizePublicController()
		selectize_public_controller.create(this.element)
	}

	disconnect() {
		var selectize_public_controller = new SelectizePublicController()
		selectize_public_controller.destroy(this.element)
	}

	toggleEditAddressEditor(event) {
		event.preventDefault()
		if (event.target.parentNode.nodeName == "svg" || event.target.nodeName == "svg") {
			this.toggleAddressEditor(event, "pulse", "pulse")
		} else {
			this.chooseAddress(event)
		}
	}

	toggleNewAddressEditor(event) {
		event.preventDefault()
		this.toggleAddressEditor(event, "slideInDown", "slideOutUp")
	}

	toggleAddressEditor(event, animationOpenType, animationCloseType) {
		if ($(this.element).hasClass('edit-mode')) {
			this.closeAddressEditor(event, animationCloseType)
		} else {
			$(this.element).closest('.cart-address-chooser').find(".cart-address").removeClass('edit-mode')
			this.openAddressEditor(event, animationOpenType)
		}
	}

	openAddressEditor(event, animationOpenType) {
		$(this.element).addClass("edit-mode").find('form').animateCss(animationOpenType)
	}

	closeAddressEditor(event, animationCloseType) {
		$(this.element).find('form').animateCss(animationCloseType, () => {
			$(this.element).removeClass("edit-mode")
		})
	}

	chooseAddress(event) {
		if (event && ($(this.element).find('a.address-chosen')[0].contains(event.target) || $(this.element).find('a.address-btn svg')[0].contains(event.target) || $(this.element).hasClass('edit-mode'))) {
			return
		}

		$(event.target).closest('.cart-address-container').find('.cart-address-btn span').html("Choosing...")

		this.cartsController.closeCartAddressChooser(event)

		Rails.fire($(this.element).find('a.address-chosen')[0], "click")
	}

	get cartsController() {
		return this.application.getControllerForElementAndIdentifier(document.getElementsByClassName("cart")[0], "marketing--carts")
	}
}